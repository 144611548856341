import React from 'react'
import AboutCss from "./About.module.css"

const AboutSection5 = () => {
    return (<>
        <div className={`  pt-1 ${AboutCss.cards} `}>
            <div className='container-fluid'>
                <div className='container mt-4 p-1'>
                    <h4>Welcome to Econx Industries Limited!</h4>
                    <p>
                        Thank you for your interest in our company. We appreciate you taking the time to visit our website.
                    </p>

                    <h4>Have a question or inquiry?</h4>
                    <p>We encourage you to contact us if you have any questions about our products, services or need assistance. You can easily leave us a message through the form on this page.</p>

                    <h4>Stay informed and connected!</h4>
                    <p>
                        If you'd like to receive updates about our latest products, services, and special offers, simply subscribe to our newsletter by entering your email address below.
                    </p>
                </div>
            </div>
        </div>
    </>)
}

export default AboutSection5