import React from 'react'
import Main from "../../../Images/banner/banner/Fruits & Vegetables.jpg"
import img1 from "../../../Images/veg/Onion.jpg"
import img2 from "../../../Images/veg/Mix Vegetables Containers.jpg"
import img3 from "../../../Images/veg/Grapes.jpg"
import img4 from "../../../Images/veg/Banana.png"
import img5 from "../../../Images/veg/Pomegranate.jpg"
import img6 from "../../../Images/veg/Green Chili.jpg"
import img7 from "../../../Images/veg/Lemon.jpg"
import img8 from "../../../Images/veg/Frozen Green Pea.jpg"
import img9 from "../../../Images/veg/Peeled Garlic.jpg"
import img10 from "../../../Images/veg/Other Fruits & Vegetables.jpg"
import { LazyLoadImage } from 'react-lazy-load-image-component';


const FreshFruitsVegetables = () => {
  return (<>

    <div className="position-relative" style={{ height: "300px" }}>
      <div className="position-relative w-100 h-100" >
        <LazyLoadImage src={Main} loading="eager" className="img-fluid d-block w-100 h-100" style={{ filter: "brightness(50%)" }} />
        <div className="position-absolute w-100 h-100 top-0 left-0  d-flex justify-content-center align-items-center text-white">
          <h2 className='text-center mt-3'>Fresh Fruits & Vegetables</h2>
        </div>
      </div>
    </div>

    <div className='container pt-1 mb-5'>
      <div className='row mt-5 '>
        <div className='col-lg-4 col-md-3 col-sm-6 '>
          <div class="card" style={{ height: "100%" }} >
            <LazyLoadImage effect="blur" class="card-img-top" src={img1} alt="Card image cap" />
            <div class="card-body">
              <h5>Onion</h5>
              <strong>Elevate Every Dish: Export Premium Indian Onions Worldwide!</strong>
              <p class="card-text">Source the world's culinary foundation from India! We export a variety of premium Indian onions, renowned for their robust flavor, crisp texture, and long shelf life. Our meticulous sorting and controlled storage ensure consistent quality, minimizing spoilage and waste. </p>
              <strong>Offer your customers a taste of culinary excellence - contact us today for a quote and discover competitive rates for global onion export! </strong>
            </div>
          </div>
        </div>

        <div className='col-lg-4 col-md-3 col-sm-6'>
          <div class="card" style={{ height: "100%" }} >
            <LazyLoadImage effect="blur" class="card-img-top" src={img2} alt="Card image cap" />
            <div class="card-body">
              <h5>Mix Vegetables Containers</h5>
              <strong>Culinary Convenience at its Finest: Export Ready-to-Cook Indian Mixed Vegetables!</strong>
              <p class="card-text">Simplify meal prep with our hygienically processed, flash-frozen mixed vegetables. We source the freshest seasonal produce from India, offering a vibrant blend perfectly proportioned for curries, stir-fries, and more. Experience authentic Indian flavor </p>
              <strong>Contact us today for a taste of culinary ease! </strong>
            </div>
          </div>
        </div>

        <div className='col-lg-4 col-md-3 col-sm-6 '>
          <div class="card" style={{ height: "100%" }}>
            <LazyLoadImage effect="blur" class="card-img-top" src={img3} alt="Card image cap" />
            <div class="card-body">
              <h5>Grapes</h5>
              <strong>Taste the Rainbow: Export India's Finest Grapes Globally!</strong>
              <p class="card-text">Indulge your customers with a burst of flavor! We export a delightful variety of premium Indian grapes, from crimson red to emerald green and sweet black varieties. Our meticulous cultivation practices and stringent quality control ensure grapes arrive at your destination plump, juicy, and bursting with fresh flavor. Offer your customers a taste of Indian sunshine.  </p>
              <strong>Contact us today to discuss custom packing and competitive rates for global export! </strong>
            </div>
          </div>
        </div>
      </div>


      <div className='row mt-5'>
        <div className='col-lg-4 col-md-4 col-sm-6 '>
          <div class="card" style={{ height: "100%" }}>
            <LazyLoadImage effect="blur" class="card-img-top" src={img4} alt="Card image cap" />
            <div class="card-body">
              <h5>Banana</h5>
              <strong>Sun-Kissed Perfection: Export Premium Indian Cavendish Bananas!</strong>
              <p class="card-text">Indulge your customers with India's finest! We export year-round, meticulously ripened Cavendish bananas, renowned for their golden-yellow skin and creamy, sweet flesh. Our stringent quality control ensures fresh arrival at your destination. </p>
              <strong>Deliver a taste of tropical sunshine - Contact us today!</strong>
            </div>
          </div>
        </div>

        <div className='col-lg-4 col-md-4 col-sm-6 '>
          <div class="card" style={{ height: "100%" }}>
            <LazyLoadImage effect="blur" class="card-img-top" src={img5} alt="Card image cap" />
            <div class="card-body">
              <h5>Pomegranate</h5>
              <strong>Unleash the Ruby Within: Export India's Premium Pomegranates!</strong>
              <p class="card-text">Source the jewel of Indian agriculture! We export arils and whole pomegranates boasting vibrant color, exceptional flavor, and meticulous handling. Our state-of-the-art processing ensures fresh, juicy arils and protects the delicate fruit.</p>
              <strong>Add a touch of exotic luxury - Contact us today!</strong>
            </div>
          </div>
        </div>

        <div className='col-lg-4 col-md-4 col-sm-6 '>
          <div class="card" style={{ height: "100%" }}>
            <LazyLoadImage effect="blur" class="card-img-top" src={img6} alt="Card image cap" />
            <div class="card-body">
              <h5>Green Chili</h5>
              <strong>Ignite Your Dishes: Export Fiery Indian Green Chilies Globally!</strong>
              <p class="card-text">Spice up your customers' kitchens with India's finest! We export a variety of fresh and dried green chilies, ranging from mild to scorching to cater to every heat preference. Grown under the Indian sun, our chilies burst with vibrant color, intense flavor, and authentic heat.</p>
              <strong>Meticulous handling ensures freshness upon arrival. Contact us today to explore the fiery potential of Indian green chilies - let's get your customers' taste buds tingling!</strong>
            </div>
          </div>
        </div>
        </div>

        <div className='row mt-5'>
          <div className='col-lg-4 col-md-4 col-sm-6 '>
            <div class="card" style={{ height: "100%" }}>
              <LazyLoadImage effect="blur" class="card-img-top" src={img7} alt="Card image cap" />
              <div class="card-body">
                <h5>Lemon</h5>
                <strong>Sunshine in Every Squeeze: Export Premium Indian Lemons Worldwide!</strong>
                <p class="card-text">Indulge your customers with the vibrant zest of India! We export premium Indian lemons, renowned for their bright yellow skin, juicy flesh, and intense, tangy flavor.  Rich in vitamin C, our lemons add a burst of freshness to any dish or beverage.</p>
                <strong>Meticulous handling ensures our lemons arrive fresh, wherever you are in the world. Contact us today and add a touch of sunshine to your products!</strong>
              </div>
            </div>
          </div>

          <div className='col-lg-4 col-md-4 col-sm-6 '>
            <div class="card" style={{ height: "100%" }}>
              <LazyLoadImage effect="blur" class="card-img-top" src={img8} alt="Card image cap" />
              <div class="card-body">
                <h5>Frozen Green Pea</h5>
                <strong>Unlock Peak Freshness: Export Premium Indian Frozen Green Peas!</strong>
                <p class="card-text">Experience the vibrant taste of India year-round! We export flash-frozen green peas, harvested at their peak and instantly locked in freshness. Our hygienic process preserves their natural sweetness, vibrant color, and essential nutrients. Packed in convenient, tamper-proof bags, our peas offer culinary inspiration and effortless convenience.</p>
                <strong>Contact us today and add a taste of India's green bounty to your frozen vegetable selection!</strong>
              </div>
            </div>
          </div>

          <div className='col-lg-4 col-md-4 col-sm-6 '>
            <div class="card" style={{ height: "100%" }}>
              <LazyLoadImage effect="blur" class="card-img-top" src={img9} alt="Card image cap" />
              <div class="card-body">
                <h5>Peeled Garlic</h5>
                <strong>Skip the Chopping, Keep the Flavor: Export Ready-to-Use Peeled Garlic from India!</strong>
                <p class="card-text">Revolutionize your kitchen prep with hygienically processed, peeled garlic cloves from India. We source the freshest garlic, expertly peeled and packed to retain its intense aroma and powerful taste. Available in various cuts and bulk quantities, our peeled garlic saves time without sacrificing quality.</p>
                <strong>Experience the convenience – Contact us today for a quote and unlock a world of culinary possibilities with Indian peeled garlic!</strong>
              </div>
            </div>
          </div>
          <div className='col-lg-4 col-md-4 col-sm-6 mt-5'>
            <div class="card" style={{ height: "100%" }}>
              <LazyLoadImage effect="blur" class="card-img-top" src={img10} alt="Card image cap" />
              <div class="card-body">
                <h5>Other Fruits & Vegetables</h5>
                <strong>Explore a World of Flavor: Discover India's Diverse Fruits & Vegetables!</strong>
                <p class="card-text">We're your one-stop shop for premium Indian fruits & vegetables beyond the ordinary! We export a vibrant array of fresh, exotic produce, from crunchy baby corn and flavorful mangoes to tangy gooseberries and earthy mushrooms.</p>
                <strong>Our meticulous sourcing and handling guarantee freshness and quality. Expand your culinary horizons - Contact us today to discover the unique flavors of India! </strong>
              </div>
            </div>
          </div>

        </div>
      

    </div>
  </>)
}

export default FreshFruitsVegetables