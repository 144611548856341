import React from 'react'
import { NavLink } from 'react-router-dom';
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa6";



const Footer = () => {
    return (<>
        <div className='container-fluid pt-1 bg-dark text-light'>
            <div className='container'>
                <div className='row d-flex justify-content-center mt-5'>
                    <div className='col-lg-3 col-md-3 col-sm-6 col-6'>
                        <h5>Produts</h5>
                        <ul className='list-unstyled '>
                            <li>
                                <NavLink to="/AssamTea" className="nav-link ">
                                    <a className='nav-link'><IoArrowForwardCircleOutline /> Assam Tea</a>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/TextileSegment" className="nav-link ">
                                    <a className='nav-link'><IoArrowForwardCircleOutline /> Textile Segment</a>

                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/PetroleumSegment" className="nav-link ">
                                    <a className='nav-link'><IoArrowForwardCircleOutline /> Petroleum Extracts</a>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/IndianSpices" className="nav-link ">
                                    <a className='nav-link'><IoArrowForwardCircleOutline /> Indian Spices</a>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/MetalSegment" className="nav-link ">
                                    <a className='nav-link'><IoArrowForwardCircleOutline /> Metal Segment</a>
                                </NavLink>
                            </li>
                        </ul>

                    </div>
                    <div className='col-lg-2 col-md-3 col-sm-6 col-6'>
                        <h5>Quick Links</h5>
                        <ul className='list-unstyled '>

                        <li>
                                <NavLink to="/" className="nav-link ">
                                    <a className='nav-link'><IoArrowForwardCircleOutline /> Home</a>

                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/About" className="nav-link ">
                                    <a className='nav-link'><IoArrowForwardCircleOutline />  AboutUs</a>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/Services" className="nav-link ">
                                    <a className='nav-link'><IoArrowForwardCircleOutline /> Services</a>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/Contact" className="nav-link ">
                                    <a className='nav-link'><IoArrowForwardCircleOutline /> Contact</a>
                                </NavLink>
                            </li>
                          
                        </ul>
                    </div>
                    <div className='col-lg-4 col-md-3 col-sm-6 col-12'>
                        <h5>Contact Us</h5>
                      <p><FaLocationDot/> Showroom 1 Second Floor,<br/>Camelot Society,Mhada Colony <br/> Viman Nagar, Pune, Maharashtra 411014</p>
                     <a class="text nav-link" href="tel:986-009-9400"> <FaPhone/> +91-9860099400</a>
                     
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-6 col-12 my-sm-3 my-lg-0 my-3'>
                        <h5 className='text-center'>Follow Us On</h5>
                        <div className=" row mt-4 fs-4 p-3 s-logo ">
                            <div className="col"> <a href="https://www.facebook.com/GENXCEL" class="bi bi-facebook text-light"></a></div>
                            <div className="col"> <a href="https://www.linkedin.com/company/genxcel-info-solutions" class="bi bi-linkedin text-light"></a></div>
                            <div className="col"><a class="bi bi-instagram text-light"></a></div>
                            <div className="col"><a href="https://wa.me/+918983000373" class="bi bi-whatsapp text-light"></a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default Footer