import React from 'react'
import img1 from "../../Images/About/b2.png"
import { LazyLoadImage } from 'react-lazy-load-image-component';

const AboutSection1 = () => {
  return (<>
      <div className="container p-1">
        <div className='row mt-4 px-2'>
          <div className='col-lg-6 col=md-6 col-sm-12 '>
            
             <LazyLoadImage src={img1} className='h-100 w-100 ' />
           
          </div>
          <div className="col-lg-6 col=md-6 col-sm-12">
            <h4>Econx Industries Limited: Building Bridges to Success</h4>
            <p className='mt-3'>Econx Industries Limited was born from the vision
              of a group of passionate and Visionary entrepreneurs committed to shaping
              the next generation business ecosystem. Recognizing the dynamic landscape
              of customer behavior, our founders designed solutions to navigate the
              competitive landscape for years to come.</p>

            <p> We believe in the immense potential of scattered and informal sectors,
              often engines of employment and revenue. Econx Industries is dedicated
              to structuring these sectors, providing a solid framework for improved
              financial benefits for farmers, informal markets and previously untapped
              areas of the economy.</p>
          </div>

        </div>
      </div>
  </>)
}

export default AboutSection1