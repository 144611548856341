import React from 'react'
import spicesCss from "./IndianSpices.module.css"
import Main from "../../../Images/banner/banner/Indianspices.jpg"
import img1 from "../../../Images/Indian Spices/Black Papper.png"
import img2 from "../../../Images/Indian Spices/White Pepper.jpg"
import img3 from "../../../Images/Indian Spices/Green Cardamom.png"
import img4 from "../../../Images/Indian Spices/Black Cardamom.jpg"
import img5 from "../../../Images/Indian Spices/Cinnamon.jpg"
import img6 from "../../../Images/Indian Spices/Bay Leaf.jpg"
import img7 from "../../../Images/Indian Spices/Clove.jpg"
import img8 from "../../../Images/Indian Spices/Nutmeg.jpg"
import img9 from "../../../Images/Indian Spices/mace1.jpg"
import img10 from "../../../Images/Indian Spices/Cumin Seeds.png"
import img11 from "../../../Images/Indian Spices/Coriander Seeds.jpg"
import img12 from "../../../Images/Indian Spices/Fennel Seeds.png"
import img13 from "../../../Images/Indian Spices/Carom Seeds.jpg"
import img14 from "../../../Images/Indian Spices/Mustard Seeds.jpg"
import img15 from "../../../Images/Indian Spices/Star Anise.jpg"
import img16 from "../../../Images/Indian Spices/Red Chili.png"
import img17 from "../../../Images/Indian Spices/Cashew Nuts.jpg"
import img18 from "../../../Images/Indian Spices/Raw Cashew Nut RCN.jpg"
import { LazyLoadImage } from 'react-lazy-load-image-component';


const IndianSpices = () => {
    return (<>
        <div className="position-relative" style={{ height: "300px" }}>
            <div className="position-relative w-100 h-100" >
                 <LazyLoadImage  src={Main} loading="eager" className="img-fluid d-block w-100 h-100" style={{ filter: "brightness(50%)" }} />
                <div className="position-absolute w-100 h-100 top-0 left-0  d-flex justify-content-center align-items-center text-white">
                    <h2 className='text-center mt-3'>Indian Spices</h2>
                </div>
            </div>
        </div>

        <div class="container mt-5">
            <div class='row'>
                <div class="col-md-6 col-xl-6 col-sm-6">
                    <div class="card pb-lg-4 pb-md-4 pb-sm-4">
                        <div class="card-body">
                            <div className='row'>
                                <div className='col-lg-3 col-12 text-center'>
                                    <div class="media align-items-center h-100 w-100">
                                         <LazyLoadImage   className={`img-fluid rounded ${spicesCss.images} `} src={img1} />
                                        {/* <span style={{ backgroundImage: "url(https://bootdey.com/img/Content/avatar/avatar6.png)" }} class="avatar avatar-xl mr-3"></span> */}
                                    </div>
                                </div>
                                <div className='col-lg-9 col-12'>
                                    <div class="media-body overflow-hidden">
                                        <h5 class="card-text mb-0">Black Pepper</h5>
                                        <p class="card-text   text-muted">Unleash the fiery bite of Indian Black Pepper. This potent spice delivers a sharp, peppery flavor with warm, woody notes, awakening your taste buds and adding depth to any dish.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 col-xl-6 col-sm-6">
                    <div class="card">
                        <div class="card-body">
                            <div className='row'>
                                <div className='col-lg-3 col-12 text-center'>
                                <div class="media align-items-center h-100 w-100">
                                         <LazyLoadImage   className={`img-fluid rounded ${spicesCss.images} `} src={img2} />
                                        
                                    </div>
                                </div>
                                <div className='col-lg-9 col-12'>
                                    <div class="media-body overflow-hidden">
                                        <h5 class="card-text mb-0">White Pepper</h5>
                                        <p class="card-text  text-muted">Experience the refined taste of Indian White Pepper. This milder alternative to black pepper offers a subtle heat with elegant, floral notes, perfect for elevating sauces and light-colored dishes without overpowering them.</p>
                                    </div>
                                </div><a href="#/" class="tile-link"></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6  col-xl-6 col-sm-6">
                    <div class="card pb-md-4 pb-sm-4">
                        <div class="card-body">
                            <div className='row'>
                                <div className='col-lg-3 col-12 text-center'>
                                <div class="media align-items-center h-100 w-100">
                                         <LazyLoadImage   className={`img-fluid rounded ${spicesCss.images} `} src={img3} />
                                        
                                    </div>
                                </div>
                                <div className='col-lg-9 col-12'>
                                    <div class="media-body overflow-hidden">
                                        <h5 class="card-text mb-0">Green Cardamom</h5>
                                        <p class="card-text   text-muted">Embrace the complex aroma of Indian Green Cardamom. These emerald pods burst with a sweet, minty flavor and citrusy undertones, adding an exotic touch to sweet and savory dishes.</p>
                                    </div>
                                </div><a href="#/" class="tile-link"></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6  col-xl-6 col-sm-6">
                    <div class="card">
                        <div class="card-body">
                            <div className='row'>
                                <div className='col-lg-3 col-12 text-center'>
                                <div class="media align-items-center h-100 w-100">
                                         <LazyLoadImage   className={`img-fluid rounded ${spicesCss.images} `} src={img4} />
                                        
                                    </div>
                                </div>
                                <div className='col-lg-9 col-12'>
                                    <div class="media-body overflow-hidden">
                                        <h5 class="card-text mb-0">Black Cardamom</h5>
                                        <p class="card-text   text-muted">Indulge in the smoky depth of Indian Black Cardamom. These larger pods offer a bold, camphoraceous aroma with hints of eucalyptus and menthol, perfect for adding exotic intrigue to curries and savory dishes.</p>
                                    </div>
                                </div><a href="#/" class="tile-link"></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6  col-xl-6 col-sm-6">
                    <div class="card  pb-xl-4 ">
                        <div class="card-body">
                            <div className='row'>
                                <div className='col-lg-3 col-12 text-center'>
                                <div class="media align-items-center h-100 w-100">
                                         <LazyLoadImage   className={`img-fluid rounded ${spicesCss.images} `} src={img5} />
                                        
                                    </div>
                                </div>
                                <div className='col-lg-9 col-12'>
                                    <div class="media-body overflow-hidden">
                                        <h5 class="card-text mb-0">Cinnamon</h5>
                                        <p class="card-text   text-muted">Warm your senses with Indian Cinnamon. These aromatic sticks boast a sweet, spicy flavor with hints of citrus and clove, adding a touch of sophistication to desserts, curries, and warm beverages.</p>
                                    </div>
                                </div><a href="#/" class="tile-link"></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6  col-xl-6 col-sm-6">
                    <div class="card pb-lg-4 pb-md-4">
                        <div class="card-body">
                            <div className='row'>
                                <div className='col-lg-3 col-12 text-center'>
                                <div class="media align-items-center h-100 w-100">
                                         <LazyLoadImage   className={`img-fluid rounded ${spicesCss.images} `} src={img6} />
                                        
                                    </div>
                                </div>
                                <div className='col-lg-9 col-12'>
                                    <div class="media-body overflow-hidden">
                                        <h5 class="card-text mb-0">Bay Leaf</h5>
                                        <p class="card-text   text-muted">Elevate your dishes with Indian Bay Leaf. This aromatic leaf infuses a warm, peppery fragrance with a hint of eucalyptus, adding subtle depth to soups, stews, and curries.</p>
                                    </div>
                                </div><a href="#/" class="tile-link"></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6  col-xl-6 col-sm-6">
                    <div class="card">
                        <div class="card-body">
                            <div className='row'>
                                <div className='col-lg-3 col-12 text-center'>
                                <div class="media align-items-center h-100 w-100">
                                         <LazyLoadImage   className={`img-fluid rounded ${spicesCss.images} `} src={img7} />
                                        
                                    </div>
                                </div>
                                <div className='col-lg-9 col-12'>
                                    <div class="media-body overflow-hidden">
                                        <h5 class="card-text mb-0">Clove</h5>
                                        <p class="card-text   text-muted">Unleash the warming intensity of Indian Cloves. These potent buds deliver a sharp, pungent flavor with hints of sweetness, adding a distinctive touch to curries, broths, and desserts.</p>
                                    </div>
                                </div><a href="#/" class="tile-link"></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6  col-xl-6 col-sm-6">
                    <div class="card">
                        <div class="card-body">
                            <div className='row'>
                                <div className='col-lg-3 col-12 text-center'>
                                <div class="media align-items-center h-100 w-100">
                                         <LazyLoadImage   className={`img-fluid rounded ${spicesCss.images} `} src={img8} />
                                        
                                    </div>
                                </div>
                                <div className='col-lg-9 col-12'>
                                    <div class="media-body overflow-hidden">
                                        <h5 class="card-text mb-0">Nutmeg</h5>
                                        <p class="card-text   text-muted">Savor the warm, aromatic depth of Indian Nutmeg. This versatile spice offers a sweet, slightly peppery flavor with hints of nuttiness, perfect for enhancing curries, baked goods, and beverages.</p>
                                    </div>
                                </div><a href="#/" class="tile-link"></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6  col-xl-6 col-sm-6">
                    <div class="card">
                        <div class="card-body">
                            <div className='row'>
                                <div className='col-lg-3 col-12 text-center'>
                                <div class="media align-items-center h-100 w-100">
                                         <LazyLoadImage   className={`img-fluid rounded ${spicesCss.images} `} src={img9} />
                                        
                                    </div>
                                </div>
                                <div className='col-lg-9 col-12'>
                                    <div class="media-body overflow-hidden">
                                        <h5 class="card-text mb-0">Mace</h5>
                                        <p class="card-text   text-muted">Experience the delicate fragrance of Indian Mace. This mesh-like aril surrounding nutmeg boasts a warm, citrusy sweetness with a hint of pepper, adding a subtle yet sophisticated touch to sweet and savory dishes.</p>
                                    </div>
                                </div><a href="#/" class="tile-link"></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6  col-xl-6 col-sm-6">
                    <div class="card pb-4">
                        <div class="card-body">
                            <div className='row'>
                                <div className='col-lg-3 col-12 text-center'>
                                <div class="media align-items-center h-100 w-100">
                                         <LazyLoadImage   className={`img-fluid rounded ${spicesCss.images} `} src={img10} />
                                        
                                    </div>
                                </div>
                                <div className='col-lg-9 col-12'>
                                    <div class="media-body overflow-hidden">
                                        <h5 class="card-text mb-0">Cumin Seeds</h5>
                                        <p class="card-text   text-muted">Unlock the earthy warmth of Cumin Seeds. These tiny powerhouses pack a bold, citrusy punch with smoky undertones, adding depth and complexity to curries, dips, and roasted vegetables.</p>
                                    </div>
                                </div><a href="#/" class="tile-link"></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6  col-xl-6 col-sm-6">
                    <div class="card pb-xl-4 pb-lg-4 pb-sm-5 pb-md-4">
                        <div class="card-body">
                            <div className='row'>
                                <div className='col-lg-3 col-12 text-center'>
                                <div class="media align-items-center h-100 w-100">
                                         <LazyLoadImage   className={`img-fluid rounded ${spicesCss.images} `} src={img11} />
                                        
                                    </div>
                                </div>
                                <div className='col-lg-9 col-12'>
                                    <div class="media-body overflow-hidden">
                                        <h5 class="card-text mb-0">Coriander Seeds</h5>
                                        <p class="card-text   text-muted">Elevate your dishes with the citrusy, floral notes of Coriander Seeds. These aromatic spheres deliver a warm, slightly peppery flavor, adding a touch of brightness and complexity to curries, marinades, and spice blends.</p>
                                    </div>
                                </div><a href="#/" class="tile-link"></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6  col-xl-6 col-sm-6">
                    <div class="card pb-4">
                        <div class="card-body">
                            <div className='row'>
                                <div className='col-lg-3 col-12 text-center'>
                                <div class="media align-items-center h-100 w-100">
                                         <LazyLoadImage   className={`img-fluid rounded ${spicesCss.images} `} src={img12} />
                                        
                                    </div>
                                </div>
                                <div className='col-lg-9 col-12'>
                                    <div class="media-body overflow-hidden">
                                        <h5 class="card-text mb-0">Fennel Seeds</h5>
                                        <p class="card-text   text-muted">Savor the licorice-like sweetness of Fennel Seeds. These aromatic seeds boast a warming, slightly peppery flavor with a hint of anise, perfect for adding digestion-aiding properties and a unique twist to breads, curries, and even desserts.</p>
                                    </div>
                                </div><a href="#/" class="tile-link"></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6  col-xl-6 col-sm-6">
                    <div class="card pb-xl-4" >
                        <div class="card-body">
                            <div className='row'>
                                <div className='col-lg-3 col-12 text-center'>
                                <div class="media align-items-center h-100 w-100">
                                         <LazyLoadImage   className={`img-fluid rounded ${spicesCss.images} `} src={img13} />
                                        
                                    </div>
                                </div>
                                <div className='col-lg-9 col-12'>
                                    <div class="media-body overflow-hidden">
                                        <h5 class="card-text mb-0">Carom Seeds</h5>
                                        <p class="card-text   text-muted">Embrace digestive ease with Carom Seeds. These tiny, oval-shaped seeds offer a warm, citrusy flavor with peppery undertones, promoting digestion and adding a unique touch to curries, vegetables, and even beverages.</p>
                                    </div>
                                </div><a href="#/" class="tile-link"></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6  col-xl-6 col-sm-6">
                    <div class="card pb-lg-5 pb-md-5 pb-sm-5" >
                        <div class="card-body">
                            <div className='row'>
                                <div className='col-lg-3 col-12 text-center'>
                                <div class="media align-items-center h-100 w-100">
                                         <LazyLoadImage   className={`img-fluid rounded ${spicesCss.images} `} src={img14} />
                                        
                                    </div>
                                </div>
                                <div className='col-lg-9 col-12'>
                                    <div class="media-body overflow-hidden">
                                        <h5 class="card-text mb-0">Mustard Seeds</h5>
                                        <p class="card-text   text-muted">Unleash the zing! Mustard Seeds deliver a sharp, pungent flavor with a warming heat, adding depth and complexity to curries, pickles, and even salad dressings.</p>
                                    </div>
                                </div><a href="#/" class="tile-link"></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6  col-xl-6 col-sm-6">
                    <div class="card">
                        <div class="card-body">
                            <div className='row'>
                                <div className='col-lg-3 col-12 text-center'>
                                <div class="media align-items-center h-100 w-100">
                                         <LazyLoadImage   className={`img-fluid rounded ${spicesCss.images} `} src={img15} />
                                        
                                    </div>
                                </div>
                                <div className='col-lg-9 col-12'>
                                    <div class="media-body overflow-hidden">
                                        <h5 class="card-text mb-0">Star Anise</h5>
                                        <p class="card-text   text-muted">Embrace the licorice-like warmth of Star Anise. This star-shaped pod boasts a sweet, peppery flavor with hints of citrus, adding a unique depth to broths, curries, and even beverages.</p>
                                    </div>
                                </div><a href="#/" class="tile-link"></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6  col-xl-6 col-sm-6">
                    <div class="card pb-xl-0 pb-lg-4 pb-md-4 pb-sm-4">
                        <div class="card-body">
                            <div className='row'>
                                <div className='col-lg-3 col-12 text-center'>
                                <div class="media align-items-center h-100 w-100">
                                         <LazyLoadImage   className={`img-fluid rounded ${spicesCss.images} `} src={img16} />
                                        
                                    </div>
                                </div>
                                <div className='col-lg-9 col-12'>
                                    <div class="media-body overflow-hidden">
                                        <h5 class="card-text mb-0">Red Chili</h5>
                                        <p class="card-text   text-muted">Ignite your taste buds with Red Chilli. This fiery spice packs a punchy heat with smoky undertones, adding excitement and depth to curries, sauces, and stir-fries.</p>
                                    </div>
                                </div><a href="#/" class="tile-link"></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6  col-xl-6 col-sm-6">
                    <div class="card pb-lg-5 pb-md-5 pb-sm-5">
                        <div class="card-body ">
                            <div className='row pb-lg-4'>
                                <div className='col-lg-3 col-12 text-center'>
                                <div class="media align-items-center h-75 w-100">
                                         <LazyLoadImage   className={`img-fluid rounded ${spicesCss.images} `} src={img17} />
                                        
                                    </div>
                                </div>
                                <div className='col-lg-9 col-12'>
                                    <div class="media-body overflow-hidden">
                                        <h5 class="card-text mb-0">W180, W210, W240, W320, W450, W500</h5>
                                        <p class="card-text   text-muted">Econx Industries supplies exceptional Cashew Nuts to wholesalers and distributors worldwide. These large-sized, creamy-textured nuts boast a rich, buttery flavor, perfect for enhancing your confectionery, bakery, and snacking products.</p>
                                    </div>
                                </div><a href="#/" class="tile-link"></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6  col-xl-6 col-sm-6">
                    <div class="card pb-xl-0 pb-lg-5">
                        <div class="card-body">
                            <div className='row'>
                                <div className='col-lg-3 col-12 text-center'>
                                <div class="media align-items-center h-75 w-100">
                                         <LazyLoadImage   className={`img-fluid rounded ${spicesCss.images} `} src={img18} />
                                        
                                    </div>
                                </div>
                                <div className='col-lg-9 col-12'>
                                    <div class="media-body overflow-hidden">
                                        <h5 class="card-text mb-0">RCN</h5>
                                        <p class="card-text   text-muted">Source Your Next Bulk Cashew Order: Raw Cashew Nut RCN
                                            Econx Industries offers high-quality Raw Cashew Nut RCN (Whole & Split) to wholesalers and manufacturers. These unroasted, unbleached cashews are sourced directly from reliable origins, ensuring freshness and consistency. Contact us today for competitive bulk pricing and seamless global trade solutions.
                                        </p>
                                    </div>
                                </div><a href="#/" class="tile-link"></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
export default IndianSpices