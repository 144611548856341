import ReactDom from "react-dom"
import { App } from "./App";
import "./index.css"

import "bootstrap/dist/css/bootstrap.css"
import "bootstrap/dist/js/bootstrap.js"
import "bootstrap/scss/bootstrap.scss"
import "bootstrap/dist/css/bootstrap.min.css"
import 'react-lazy-load-image-component/src/effects/blur.css';


ReactDom.render(<App/>,document.getElementById("root"));