import React from 'react'
import NavCss from "./Nav.module.css"
import { NavLink } from 'react-router-dom'
import logo from "../../Images/Econx.png"

const Nav = () => {
    return (<>
        <div className={`${NavCss.navMain} ` }>
            <nav class={`navbar navbar-expand-lg  fixed-top ${NavCss.navbar}`}>
                <div class="container-fluid mx-3">
                    <img class="navbar-brand fw-medium" loading="lazy" style={{ width: "30vh" }} src={logo} />
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarNav">
                        <ul class="navbar-nav ms-auto gap-5 ">
                            <li class="nav-item">
                                <NavLink to="/" className="nav-link" >
                                    <button class="bg-transparent border-0 fw-bold" data-bs-toggle="collapse" data-bs-target="#navbarNav" >Home</button>
                                </NavLink>
                            </li>
                            <li class="nav-item">
                                <NavLink to="/About" className="nav-link">
                                    <button class="bg-transparent border-0 fw-bold" data-bs-toggle="collapse" data-bs-target="#navbarNav" >
                                        About Us
                                    </button>
                                </NavLink>
                            </li>
                            <li class="nav-item dropdown ">
                                <NavLink className="nav-link ">
                                    <button class="bg-transparent border-0 fw-bold">Products</button>
                                </NavLink>
                                <ul class="dropdown-menu navbar-link">
                                    <li>
                                        <NavLink to="/Commodities" className="nav-link">
                                            <button class="bg-transparent border-0 fw-bold" data-bs-toggle="collapse" data-bs-target="#navbarNav" >
                                                Commodities
                                            </button>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/FreshFruits&Vegetables" className="nav-link">
                                            <button class="bg-transparent border-0 fw-bold text-start" data-bs-toggle="collapse" data-bs-target="#navbarNav" >
                                                Fresh Fruits & Vegetables
                                            </button>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/MetalSegment" className="nav-link ">
                                            <button class="bg-transparent border-0 fw-bold" data-bs-toggle="collapse" data-bs-target="#navbarNav" >
                                                Metal Segment
                                            </button>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/AssamTea" className="nav-link ">
                                            <button class="bg-transparent border-0 fw-bold" data-bs-toggle="collapse" data-bs-target="#navbarNav" >
                                                Assam Tea
                                            </button>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/IndianSpices" className="nav-link ">
                                            <button class="bg-transparent border-0 fw-bold" data-bs-toggle="collapse" data-bs-target="#navbarNav" >
                                                Indian Spices
                                            </button>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/EdibleOil" className="nav-link ">
                                            <button class="bg-transparent border-0 fw-bold" data-bs-toggle="collapse" data-bs-target="#navbarNav" >
                                                Edible Oil
                                            </button>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/DehydratedProducts" className="nav-link ">
                                            <button class="bg-transparent border-0 text-start fw-bold" data-bs-toggle="collapse" data-bs-target="#navbarNav">Dehydrated Products
                                            </button>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/TextileSegment" className="nav-link">
                                            <button class="bg-transparent border-0 fw-bold" data-bs-toggle="collapse" data-bs-target="#navbarNav" >
                                                Textile Segment
                                            </button>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/PetroleumSegment" className="nav-link ">
                                            <button class="bg-transparent border-0 text-start fw-bold" data-bs-toggle="collapse" data-bs-target="#navbarNav" >
                                                Petroleum Extracts
                                            </button>
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li class="nav-item">
                                <NavLink to="/Services" className="nav-link">
                                    <button class="bg-transparent border-0 fw-bold" data-bs-toggle="collapse" data-bs-target="#navbarNav" >
                                        Services
                                    </button>
                                </NavLink>
                            </li>
                            <li class="nav-item">
                                <NavLink to="/Contact" className="nav-link ">
                                    <button class="bg-transparent border-0 fw-bold" data-bs-toggle="collapse" data-bs-target="#navbarNav" >
                                        Contact Us
                                    </button>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    </>)
}
export default Nav