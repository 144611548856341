    import React from 'react'
    import img1 from "../../Images/About/a3.jpg"
    import img2 from "../../Images/About/a4.jpg"
    import { AnimatedOnScroll } from 'react-animated-css-onscroll'
    import { LazyLoadImage } from 'react-lazy-load-image-component';

   
    const AboutSection3 = () => {
        return (
            <div className='container pt-1'>
                <div className='row mt-5'>
                    <h3 className='text-center mb-4'>Our Mission & Vision</h3>
                    
                    <div className='col-lg-6'>
                    <AnimatedOnScroll animationIn="fadeInLeftBig">
                        <div className="position-relative" style={{ height: "300px" }}>
                            <div className="position-relative w-100 h-100" >
                               <LazyLoadImage   src={img1}  className="img-fluid d-block w-100 h-100" style={{ filter: "brightness(50%)" }} />
                                <div className="position-absolute w-100 h-100 top-0 left-0 p-3 d-inline justify-content-center align-items-center text-white">
                                    <h4 className='text-center mt-3'>  Mission</h4><br />
                                    <p>We build strong, long-term partnerships to empower
                                        our associates and deliver exceptional products
                                        and solutions with outstanding service quality.
                                        This allows us to expand our reach globally,
                                        acquire new opportunities, and operate efficiently.</p>
                                </div>
                                <div className=" w-100 h-100 top-0 left-0  d-flex justify-content-center align-items-center text-white">
                                </div>
                            </div>
                        </div>
                        </AnimatedOnScroll>
                    </div>
                    
                    <div className='mt-lg-0 mt-3 col-lg-6'>
                    <AnimatedOnScroll animationIn="fadeInRightBig">
                        <div className="position-relative" style={{ height: "300px" }}>
                            <div className="position-relative w-100 h-100" >
                                 <LazyLoadImage  src={img2}  className="img-fluid d-block w-100 h-100" style={{ filter: "brightness(50%)" }} />
                                <div className="position-absolute w-100 h-100 top-0 left-0  p-3 d-inline justify-content-center align-items-center text-white">
                                    <h4 className='text-center mt-3'> Vision</h4><br />
                                    <p>To become a global leader in the supply chain,
                                        championing Indian agricultural and natural products
                                        on the world stage. We strive for sustainable growth
                                        by empowering farmers and Indigenous industries
                                        through fair trade practices and supporting women's
                                        economic participation.</p>
                                </div>
                            </div>
                        </div>
                        </AnimatedOnScroll>
                    </div>
                    
                </div>
            </div>
        )
    }

    export default AboutSection3