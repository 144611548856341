import React from 'react'

const ContactSection2 = () => {
    return (<>
        <div className='container'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m19!1m12!1m3!1d121026.40079367242!2d73.8312196525942!3d18.57065604549622!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m4!3e6!4m0!4m1!2sShowroom%201%20Second%20Floor%2C%20Camelot%20Society%2CMhada%20Colony%20Viman%20Nagar%2C%20Pune%2C%20Maharashtra%20411014!5e0!3m2!1sen!2sin!4v1711083025752!5m2!1sen!2sin"
                width="100%" height="450" style={{ border: "0" }}
                allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </>)
}

export default ContactSection2