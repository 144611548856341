import React from 'react'
import ProductCss from "../Product.module.css"
import Main from "../../../Images/banner/banner/tea.png"
import img1 from "../../../Images/AssamTea/ctc-Tea.png"
import img2 from "../../../Images/AssamTea/Orthodox Tea.jpg"
import img3 from "../../../Images/AssamTea/Green Tea.png"
import img4 from "../../../Images/AssamTea/Oolong Tea.jpg"
import img5 from "../../../Images/AssamTea/White Tea.jpg"
import img6 from "../../../Images/AssamTea/Flavor Tea.jpg"
import img7 from "../../../Images/AssamTea/Dip Tea.jpg"
import { LazyLoadImage } from 'react-lazy-load-image-component';


const AssamTea = () => {
  return (<>
    <div className="position-relative" style={{ height: "300px"  }}>
      <div className="position-relative w-100 h-100 img-fluid" >
         <LazyLoadImage  src={Main} loading="eager" className="img-fluid d-block w-100 h-100" style={{ filter: "brightness(50%)" }} />
        <div className="position-absolute w-100 h-100 top-0 left-0  d-flex justify-content-center align-items-center text-white">
          <h2 className='text-center mt-3'>Assam Tea</h2>
        </div>
      </div>
    </div>

    <div className={`container pt-1 ${ProductCss.Images}`}>
      <div className='row mt-5'>
        <div className='col-lg-4 col-md-4 col-sm-6 '>
          <div class="card pb-4" >
             <LazyLoadImage effect="blur" class="card-img-top " src={img1} alt="Card image cap" />
            <div class="card-body">
              <h5>CTC Tea</h5>
              <p class="card-text">Awaken your senses with bold and malty Assam CTC Tea. This full-bodied black tea offers a robust flavor, perfect for a morning pick-me-up or an afternoon reinvigoration.</p>
            </div>
          </div>
        </div>

        <div className='col-lg-4 col-md-4 col-sm-6 '>
          <div class="card pb-4" >
             <LazyLoadImage effect="blur" class="card-img-top" src={img2} alt="Card image cap" />
            <div class="card-body">
              <h5>Orthodox Tea</h5>
              <p class="card-text">Awaken your senses with ASSAM Orthodox Tea. This whole-leaf, full-bodied black tea offers a malty flavor and invigorating aroma for a truly exceptional tea experience.</p>
            </div>
          </div>
        </div>

        <div className='col-lg-4 col-md-4 col-sm-6 '>
          <div class="card" >
             <LazyLoadImage effect="blur" class="card-img-top" src={img3} alt="Card image cap" />
            <div class="card-body">
              <h5>Green Tea</h5>
              <p class="card-text">Embrace wellness with ASSAM Green Tea. This delicate, unoxidized tea boasts a subtle sweetness and refreshing aroma, rich in antioxidants for a healthy and invigorating cup.</p>
            </div>
          </div>
        </div>
      
          <div className='col-lg-4 col-md-4 col-sm-6 '>
            <div class="card" >
               <LazyLoadImage effect="blur" class="card-img-top" src={img4} alt="Card image cap" />
              <div class="card-body">
                <h5>Oolong Tea</h5>
                <p class="card-text">Discover the unique of ASSAM Oolong Tea. This partially oxidized tea bridges black and green varieties, offering a smooth, well-rounded taste with fruity notes and a hint of floral aroma for a truly distinctive tea experience.</p>
              </div>
            </div>
          </div>

        <div className='col-lg-4 col-md-4 col-sm-6 '>
          <div class="card" >
             <LazyLoadImage effect="blur" class="card-img-top" src={img5} alt="Card image cap" />
            <div class="card-body">
              <h5>White Tea</h5>
              <p class="card-text">Indulge in the pure essence of tea with ASSAM White Tea. These young, minimally processed leaves deliver a light, delicate flavor with subtle sweetness and a silky smooth texture, offering a cup of pure elegance and wellness.</p>
            </div>
          </div>
        </div>

        <div className='col-lg-4 col-md-4 col-sm-6 '>
          <div class="card" >
             <LazyLoadImage effect="blur" class="card-img-top" src={img6} alt="Card image cap" />
            <div class="card-body">
              <h5>Flavor Tea</h5>
              <p class="card-text">Explore the exciting world of ASSAM Flavor Tea! Experience a symphony of flavors with our carefully crafted blends. From citrusy and floral to spiced and chocolatey, discover your perfect cup and embrace the taste of adventure.</p>
            </div>
          </div>
        </div>

        <div className='col-lg-4 col-md-4 col-sm-6 '>
        <div class="card" >
           <LazyLoadImage effect="blur" class="card-img-top" src={img7} alt="Card image cap" />
          <div class="card-body">
            <h5>Dip Tea</h5>
            <p class="card-text">Savor the richness and strength of ASSAM Dip Tea. This bold black tea is perfect for milk and sugar, offering a robust and comforting cup to kickstart your day or warm your soul.</p>
          </div>
        </div>
      </div>
      </div>
      </div>
  </>)
}

export default AssamTea