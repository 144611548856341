import React from 'react'
import HomeCss from "./Home.module.css"


const HomeSection2 = () => {
    return (<>
    <div className={`container-fluid mt-5 pt-1 ${HomeCss.sec2}`}>
        <div className='container '>
            <h4 className='mt-5 text-center'>Our Expertise:</h4>
            <div className='row'>
                <h6 className='text-center mb-5 mt-2'>Econx Industries caters to a variety of sectors, including:</h6>
                <div className='col'>
                    <h5>Commodities</h5>
                    <p> We manage the trade of essential goods like grains, metals, and energy products.</p>
                </div>
                <div className='col'>
                    <h5>Textiles</h5>
                    <p>We facilitate the export of high-quality textiles, including fabrics, garments, and yarn.</p>
                </div>
                <div className='col'>
                    <h5>Oil Seeds & Edible Oils</h5>
                    <p>We connect buyers and sellers of oilseeds and processed edible oils.</p>
                </div>
            </div>

            <div className='row mt-3'>
                <div className='col'>
                    <h5>Petroleum Extracts</h5>
                    <p> We manage the trade of petroleum derivatives and byproducts.</p>
                </div>
                <div className='col'>
                    <h5>LME Products</h5>
                    <p>We handle transactions involving metals traded on the London Metal Exchange.</p>
                </div>
                <div className='col'>
                    <h5>Special Purpose Machinery & Plant Parts</h5>
                    <p>We facilitate the export of specialized machinery and components.</p>
                </div>
            </div>
        </div>
        </div>
  
    </>)
}

export default HomeSection2