import React from 'react'
import AboutMainSection from './AboutMainSection'
import AboutSection1 from './AboutSection1'
import AboutSection2 from './AboutSection2'
import AboutSection3 from './AboutSection3'
import AboutSection4 from './AboutSection4'
import AboutSection5 from './AboutSection5'

const About = () => {
  return (<>
    <AboutMainSection />
    <AboutSection1 />
    <AboutSection2/>
    <AboutSection3/>
    <AboutSection4/>
    <AboutSection5/>
  </>)
}
export default About