import React from 'react'
import { useEffect, useState } from 'react'
import img1 from "../../Images/1583274_Cargo Ship_Container Ship_Container Port_1920x1080.mp4"
import HomeCss from "./Home.module.css"

export const HomeImg = () => {
    const [count, setCount] = useState(document.getElementsByClassName("effect"));
    return (<>
        <div>
            <div className={`${HomeCss.Video}`}>
                <video autoPlay loop muted preload="play" >
                    <source className={HomeCss.VideoEffect} src={img1} type="video/mp4" />
                    Your browser does not support HTML5 video.
                </video>
            </div>
        </div>
    </>)
}
