import React, { useEffect } from 'react'
import { HomeImg } from './HomeImg'
import HomeSection from './HomeSection'
import HomeSection2 from './HomeSection2'
import HomeSection3 from './HomeSection3'


const Home = () => {
 
  return (<>

    <HomeImg />
    <HomeSection />
    <HomeSection2 />
    <HomeSection3/>
  </>);
}
export default Home