import React from 'react';
import './EdibleOil.css';
import ProductCss from "../Product.module.css"

import Main from "../../../Images/banner/banner/Edible Oil.jpg"
import img1 from "../../../Images/Oil/Refined Sunflower Oil.jpg"
import img2 from "../../../Images/Oil/Refined Soybean Oil.jpg"
import img3 from "../../../Images/Oil/Mustard Oil.png"
import img4 from "../../../Images/Oil/Peanut Oil.PNG"
import img5 from "../../../Images/Oil/Rice brand Oil.jpg"
import img6 from "../../../Images/Oil/Olive Oil.jpg"
import img7 from "../../../Images/Oil/Coconut Oil.jpg"
import { LazyLoadImage } from 'react-lazy-load-image-component';




function EdibleOil() {

  return (<>
    <div className="position-relative" style={{ height: "300px" }}>
      <div className="position-relative w-100 h-100" >
         <LazyLoadImage  src={Main} loading="eager" className="img-fluid d-block w-100 h-100" style={{ filter: "brightness(50%)" }} />
        <div className="position-absolute w-100 h-100 top-0 left-0  d-flex justify-content-center align-items-center text-white">
          <h2 className='text-center mt-3'>Edible Oil</h2>
        </div>
      </div>
    </div>

    <div className={`container pt-1 ${ProductCss.Images}`}>
      <div className='row mt-5'>
        <div className='col-lg-4 col-md-4 col-sm-6 '>
          <div class="card pb-4 pb-xl-5" >
             <LazyLoadImage effect="blur" class="card-img-top " src={img1} alt="Card image cap" />
            <div class="card-body">
              <h5>Refined Sunflower Oil</h5>
              <strong>Bulk Refined Sunflower Oil: Source Quality with Econx</strong>
              <p class="card-text">Econx Industries offers high-quality, refined sunflower oil in bulk for food manufacturers and distributors. This versatile oil boasts a neutral taste and high smoke point, making it ideal for frying, baking, and salad dressings. Econx provides competitive pricing, reliable sourcing, and efficient global logistics. </p>
            </div>
          </div>
        </div>

        <div className='col-lg-4 col-md-4 col-sm-6 '>
          <div class="card pb-lg-5 pb-md-4 pb-sm-5" >
             <LazyLoadImage effect="blur" class="card-img-top" src={img2} alt="Card image cap" />
            <div class="card-body">
              <h5>Refined Soybean Oil</h5>
              <strong>Econx: Your Bulk Refined Soyabean Oil Partner</strong>
              <p class="card-text">Econx Industries supplies premium refined soybean oil in bulk for food businesses. This cost-effective oil is versatile for frying, baking, and dressings, boasting a neutral taste and high smoke point. Benefit from competitive pricing, reliable sourcing, and efficient global trade solutions.</p>
            </div>
          </div>
        </div>

        <div className='col-lg-4 col-md-4 col-sm-6 '>
          <div class="card pb-lg-5 pb-md-4 " >
             <LazyLoadImage effect="blur" class="card-img-top" src={img3} alt="Card image cap" />
            <div class="card-body">
              <h5>Mustard Oil</h5>
              <strong>Unlock Flavorful Culinary Experiences: Bulk Mustard Oil by Econx</strong>
              <p class="card-text">Econx Industries offers high-quality mustard oil in bulk for food manufacturers and distributors. This pungent oil adds a unique depth of flavor to curries, pickles, and various cuisines. Econx provides competitive pricing, reliable sourcing, and efficient global trade solutions.</p>
            </div>
          </div>
        </div>

        <div className='col-lg-4 col-md-4 col-sm-6 '>
          <div class="card pb-lg-5 pb-md-4 pb-xl-5" >
             <LazyLoadImage effect="blur" class="card-img-top" src={img4} alt="Card image cap" />
            <div class="card-body">
              <h5>Peanut Oil</h5>
              <strong>Bulk Peanut Oil Supply from Econx</strong>
              <p class="card-text">Econx Industries offers high-quality peanut oil in bulk for food manufacturers and distributors. This nutty-flavored oil boasts a high smoke point, making it ideal for frying, stir-frying, and salad dressings. Econx provides competitive pricing, reliable sourcing, and efficient global trade solutions.</p>
            </div>
          </div>
        </div>

        <div className='col-lg-4 col-md-4 col-sm-6 '>
          <div class="card pb-lg-5 pb-md-4 pb-sm-4 pb-xl-0" >
             <LazyLoadImage effect="blur" class="card-img-top" src={img5} alt="Card image cap" />
            <div class="card-body">
              <h5>Rice brand Oil</h5>
              <strong>Econx: Your Source for Healthy Bulk Rice Bran Oil</strong>
              <p class="card-text">Econx Industries supplies premium rice bran oil in bulk for health-conscious food businesses. This nutrient-rich oil boasts a high smoke point and a mild flavor, making it perfect for frying, baking, and salad dressings. Econx offers competitive pricing, reliable sourcing, and efficient global trade solutions.</p>
            </div>
          </div>
        </div>

        <div className='col-lg-4 col-md-4 col-sm-6 '>
          <div class="card pb-lg-4 pb-xl-4" >
             <LazyLoadImage effect="blur" class="card-img-top" src={img6} alt="Card image cap" />
            <div class="card-body">
              <h5>Olive Oil</h5>
              <strong>Enhance Culinary Delights: Bulk Olive Oil Supply by Econx</strong>
              <p class="card-text">Econx Industries offers high-quality olive oil in bulk for food manufacturers and distributors. Choose from various grades, from light and fruity to robust and intense, to perfectly complement your culinary creations. Econx provides competitive pricing, reliable sourcing, and efficient global trade solutions.</p>
            </div>
          </div>
        </div>

        <div className='col-lg-4 col-md-4 col-sm-6 '>
          <div class="card " >
             <LazyLoadImage effect="blur" class="card-img-top" src={img7} alt="Card image cap" />
            <div class="card-body">
              <h5>Coconut Oil</h5>
              <strong>Bulk Coconut Oil Supply by Econx</strong>
              <p class="card-text">Econx Industries offers high-quality, expeller-pressed coconut oil in bulk for food, cosmetic, and pharmaceutical applications. This lauric acid-rich oil is versatile for cooking, hair care, and more. Econx provides competitive pricing, reliable sourcing, and efficient global trade solutions. </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>)
}
export default EdibleOil;