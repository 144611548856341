import React from 'react'
import img1 from "../../Images/About/b3.png"
import { LazyLoadImage } from 'react-lazy-load-image-component';


const AboutMainSection = () => {
  return (<>
    <div className="position-relative" style={{ height: "300px" }}>
      <div className="position-relative w-100 h-100" >
         <LazyLoadImage  src={img1} loading="eager" className="img-fluid d-block w-100 h-100" style={{ filter: "brightness(50%)" }} />
        <div className="position-absolute w-100 h-100 top-0 left-0  d-flex justify-content-center align-items-center text-white">
          <h2 className='text-center mt-3'>About Us</h2>
        </div>
      </div>
    </div>
  </>)
}

export default AboutMainSection