import React from 'react'
import AboutCss from "./About.module.css"
import { FaCheckCircle } from "react-icons/fa";


const AboutSection2 = () => {
  return (<>
    <div className='container pt-1'>
      <div className={` row mt-5 g-4  ${AboutCss.cards} `}>

        <div className="col-lg-3 col-md-6 col-sm-6 col-12  ">
          <div className={`card p-3 ${AboutCss.card}`} style={{ height: "100%" }}>
            <strong >Our core values guide us in everything we do. We excel in:</strong>
            <p className='mt-3'><FaCheckCircle />  <strong>Merchant Export & Import</strong> Facilitating the seamless movement of essential goods.</p>
            <p><FaCheckCircle /> <strong >Bulk Trade</strong>: Managing large-scale transactions efficiently.</p>
            <p><FaCheckCircle /> <strong>SME Business Consulting: </strong>Providing expert guidance to small and medium-sized enterprises.</p>

          </div>
        </div>

        <div className='col-lg-3 col-md-6 col-sm-6 col-12 '>
          <div className={`card p-3  ${AboutCss.card}`} style={{ height: "100%" }}>
            <strong>Econx acts as a bridge to success for SMBs in India and abroad. We leverage our expertise to</strong>
            <p className='mt-3'><FaCheckCircle /> <strong>Promote products & services</strong>: Expanding market reach for our partners.</p>
            <p><FaCheckCircle /> <strong>Secure overseas business</strong>: Connecting local businesses with global opportunities.</p>

          </div>
        </div>

        <div className='col-lg-3 col-md-6 col-sm-6 col-12 '>
          <div className={`card p-3 ${AboutCss.card}`} style={{ height: "100%" }}>
            <strong> Driven by positive feedback, we've amplified our offerings. Now, Econx offers a wider range of products, including</strong>
            <p className='mt-3'><FaCheckCircle /> <strong>Organically sourced food</strong>: Promoting healthy living and ethical trade practices.</p>
            <p><FaCheckCircle /> <strong>Essential commodities</strong>: Ensuring access to daily necessities.</p>
            <p><FaCheckCircle /> <strong>Handicrafts and textiles</strong>: Celebrating cultural heritage and craftsmanship.</p>

          </div>
        </div>

        <div className='col-lg-3 col-md-6 col-sm-6 col-12  '>
          <div className={`card p-3 ${AboutCss.card} `} style={{ height: "100%" }}>
            <strong>  Econx goes beyond just business. We are passionate Commitment to Well-being of:</strong>
            <p className='mt-3'><FaCheckCircle /> <strong>Fair trade</strong>: Upholding ethical practices throughout the supply chain.</p>
            <p><FaCheckCircle /> <strong>Innovation</strong>: Continuously seeking new ways to improve our products and services.</p>
            <p><FaCheckCircle /> <strong>Health and wellness</strong>: Promoting a healthier lifestyle through organic food options.</p>

          </div>
        </div>

      </div>
    </div>
  </>)
}

export default AboutSection2