import React from 'react'
import ContactMainSec from './ContactMainSec'
import ContactSection1 from './ContactSection1'
import ContactSection2 from './ContactSection2'

const Contact = () => {
  return (<>
  <ContactMainSec/>
    <ContactSection1/>
    <ContactSection2/>
  </>)
}

export default Contact