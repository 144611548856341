import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import ContactCss from "./Contact.module.css"
import { FaLocationDot } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

const ContactSection1 = () => {
    const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_bcacj6a', 'template_2s2byj6', form.current, {
        publicKey: 'L2WaVIuALZuaCDeQn',
    })
      .then(() => {
        alert('SUBMITED SUCCESSFULLY!');
        window.location.reload(false);
      },
        (error) => {
          alert('FAILED TO SUBMIT', error.text);
          window.location.reload(false);
        },
      );
  };
    return (<>
        <div className='container pt-1'>
            <div class={`${ContactCss.FormMainDiv} mt-5 mb-5 ms-lg-2 ms-3`}>
                <div class={`${ContactCss.content}`}>
                    <div class={`${ContactCss.leftSide}`}>
                        <div class={`address ${ContactCss.details}`}>
                            <i><FaLocationDot /></i>
                            <div class={`${ContactCss.topic}`}>Address</div>
                            <div class={`${ContactCss.textOne}`}> Showroom 1 Second Floor,</div>
                            <div class={`${ContactCss.textTwo}`}> Camelot Society,Mhada Colony,<br/> Viman Nagar, Pune, Maharashtra 411014</div>
                        </div>
                        <div class={`phone ${ContactCss.details}`}>
                            <i><FaPhoneAlt /></i>
                            <div class={`${ContactCss.topic}`}>Phone</div>
                            <div class={`${ContactCss.textOne}`}> <a class="text nav-link" href="tel:986-009-9400">+918983000373</a></div>

                        </div>
                        <div class={`email ${ContactCss.details}`}>
                            <i ><MdEmail /></i>
                            <div class={`${ContactCss.topic}`}>Email</div>
                            <div class={`${ContactCss.textOne}`}><a class="text nav-link" href="mailto:">Pune@econxi.com</a></div>
                            <div class={`${ContactCss.textTwo}`}><a class="text nav-link" href="mailto:">Econxindustries@gmail.com</a></div>
                        </div>
                    </div>
                    <div class={`${ContactCss.rightSide}`}>
                        <div class={`${ContactCss.topicText}`}>Send us a message</div>
                        <p>you can send me message from here. </p>
                        <form ref={form} onSubmit={sendEmail}>
                            <div class={`${ContactCss.inputBox}`}>
                                <input  type="text" name="name" placeholder='Your Name' pattern="[A-Za-z\s]+" required="required"/>
                            </div>
                            <div class={`${ContactCss.inputBox}`}>
                                <input type="number" id="phno"  name="user_number" placeholder='Number' pattern="[0-9]{10}"  required="required"/>
                            </div>
                            <div class={`${ContactCss.inputBox}`}>
                                <input type="email" id="email" name="email" placeholder='Your Email'  required="required" />
                            </div>
                            <div class={`${ContactCss.inputBox} ${ContactCss.messageBox}`}>
                                <textarea className={`${ContactCss.TextArea}`} type="text" id="adr" style={{paddingBottom:"100px"}} name="message" placeholder='Meaasge..'   required="required"></textarea>
                            </div>
                            <div class={`${ContactCss.button}`}>
                                <input className={`${ContactCss.Btn}`}  type="submit" value="Send Message" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default ContactSection1