import React from 'react'
import ProductCss from "../Product.module.css"
import Main from "../../../Images/banner/banner/rice.jpg"
import img1 from "../../../Images/RiceImages/sella 1121 steam rice.jpg"
import img2 from "../../../Images/RiceImages/1121 Golden Sella.jpg"
import img3 from "../../../Images/RiceImages/1121 White Sella.jpg"
import img4 from "../../../Images/RiceImages/IR 64 & 36 Parboiled Rice.jpg"
import img5 from "../../../Images/RiceImages/1121 Pusha white.avif"
import img6 from "../../../Images/RiceImages/Sharbati Rice.jpg"
import img7 from "../../../Images/RiceImages/IR Raw Rice.avif"

import { LazyLoadImage } from 'react-lazy-load-image-component';

const CommoditiesMain = () => {
  return (<>
    <div className="position-relative" style={{ height: "300px" }}>
      <div className="position-relative w-100 h-100" >
        <LazyLoadImage src={Main} loading="eager" className="img-fluid d-block w-100 h-100" style={{ filter: "brightness(50%)" }} />
        <div className="position-absolute w-100 h-100 top-0 left-0  d-flex justify-content-center align-items-center text-white">
          <h2 className='text-center mt-3'>Rice Segment</h2>
        </div>
      </div>
    </div>

    <div className={`container pt-1 ${ProductCss.Images}`}>
      <div className='row mt-5 '>
        <div className='col-lg-4 col-md-4 col-sm-6 '>
          <div class="card" >
            <LazyLoadImage effect="blur" class="card-img-top" src={img1} alt="Card image cap" />
            <div class="card-body">
              <h5>1121 Steam Sella</h5>
              <p class="card-text">Experience the luxurious aroma and fluffy texture of 1121 Steam Sella Basmati Rice. This premium long-grain rice elongates beautifully upon cooking, making it the perfect choice for elegant meals.</p>
            </div>
          </div>
        </div>

        <div className='col-lg-4 col-md-4 col-sm-6 '>
          <div class="card pb-4" >
            <LazyLoadImage effect="blur" class="card-img-top" src={img2} alt="Card image cap" />
            <div class="card-body">
              <h5>1121 Golden Sella</h5>
              <p class="card-text">Indulge in the rich, golden color and nutty aroma of 1121 Golden Sella Basmati Rice. This exquisite long-grain rice cooks to fluffy perfection, ideal for elevating any dish.</p>
            </div>
          </div>
        </div>

        <div className='col-lg-4 col-md-4 col-sm-6 '>
          <div class="card" >
            <LazyLoadImage effect="blur" class="card-img-top" src={img3} alt="Card image cap" />
            <div class="card-body">
              <h5>1121 White Sella</h5>
              <p class="card-text">Savor the pearly white elegance of 1121 White Sella Basmati Rice. This aromatic long-grain rice separates perfectly, cooks up fluffy, and adds a touch of sophistication to every meal.</p>
            </div>
          </div>
        </div>

        <div className='col-lg-4 col-md-4 col-sm-6 '>
          <div class="card pb-4" >
            <LazyLoadImage effect="blur" class="card-img-top" src={img4} alt="Card image cap" />
            <div class="card-body">
              <h5>IR 64 & 36 Parboiled Rice</h5>
              <p class="card-text">Unleash the versatility of IR 64 & 36 Parboiled Rice. This non-sticky, elongated grain rice stays fluffy after cooking, making it perfect for everyday meals, biryani, or pulao.</p>
            </div>
          </div>
        </div>

        <div className='col-lg-4 col-md-4 col-sm-6 '>
          <div class="card" >
            <LazyLoadImage effect="blur" class="card-img-top" src={img5} alt="Card image cap" />
            <div class="card-body">
              <h5>1121 Pusha white</h5>
              <p class="card-text">Experience the delicate sweetness of 1121 Pusha White Basmati Rice. This extra-long grain rice cooks to a pearly white perfection, boasting a subtle aroma and light, fluffy texture for a truly elegant dining experience.</p>
            </div>
          </div>
        </div>

        <div className='col-lg-4 col-md-4 col-sm-6 '>
          <div class="card" >
            <LazyLoadImage effect="blur" class="card-img-top" src={img6} alt="Card image cap" />
            <div class="card-body">
              <h5>Sharbati Rice</h5>
              <p class="card-text">Savor the exquisite taste and aroma of Sharbati Rice. This medium-grain variety boasts a unique sweetness and delicate texture, perfect for everyday meals or special occasions. Enjoy the difference!</p>
            </div>
          </div>
        </div>

        <div className='col-lg-4 col-md-4 col-sm-6 '>
          <div class="card" >
            <LazyLoadImage effect="blur" class="card-img-top" src={img7} alt="Card image cap" />
            <div class="card-body">
              <h5>IR Raw Rice</h5>
              <p class="card-text">Unlock the nutritional power of IR Raw Rice. This unpolished grain retains bran and germ for increased fiber, vitamins, and minerals, offering a healthy and flavorful base for your meals.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </>)
}
export default CommoditiesMain