import React, { useState } from 'react';
import './AngleUp.css'; // Import CSS file for styling
import { TfiAngleDoubleUp } from "react-icons/tfi";

function ScrollToTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  // Function to scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Optional, smooth scrolling animation
    });
  };

  // Function to handle scroll event and toggle button visibility
  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    if (scrollTop > 300) { // Change this value as needed
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Add scroll event listener when component mounts
  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`scroll-to-top ${isVisible ? 'show' : 'hide'}`}>
      <button onClick={scrollToTop} >
      <TfiAngleDoubleUp/>
      </button>
    </div>
  );
}

export default ScrollToTopButton;
