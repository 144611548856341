import React from 'react'
import img1 from "../../Images/HomeImg.jpg"
import HomeCss from "./Home.module.css"
import { AnimatedOnScroll } from "react-animated-css-onscroll";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const HomeSection = () => {
    return (
        <>
      
                   
              
            <div  className={` container  position-relativeas  pt-1 ${HomeCss.paragraph}`}>
                <div className="container p-1">
                    <div className='row mt-5 px-2'>
                        <div className="col-lg-6 col=md-6 col-sm-12">
                            <h4>Econx Industries: Your Global Trading Partner</h4>
                            <h5>Unleashing India's Export Potential:</h5>
                            <p className='mt-3'>Econx Industries envisions the world as a global marketplace
                                and operates as a merchant exporter and trader, facilitating
                                the movement of a diverse range of products across
                                international borders. We are unbound by product limitations
                                (excluding those restricted by local regulations) and
                                committed to empowering the domestic economy by capturing
                                a significant share of the overseas market.</p>
                            <h5> Partnering for Success:</h5>
                            <p> We are committed to building long-term partnerships with businesses
                                of all sizes, providing expert guidance and seamless trade solutions.
                                Contact us today to explore how Econx can help you navigate the
                                global marketplace and achieve your export goals!</p>
                        </div>
                        <div className='col-lg-6 col=md-6 col-sm-12 '>
                        <AnimatedOnScroll animationIn="fadeIn"> 
                         <LazyLoadImage effect="blur" src={img1} className='h-100 w-100 ' />
                        </AnimatedOnScroll>
                        </div>
                    </div>
                </div>
            </div>
            
        </>)
}
export default HomeSection

