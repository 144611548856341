import React from 'react'
import Main from "../../../Images/banner/banner/Petrolium Segment.jpg"
import img1 from "../../../Images/Petrolium/Bitumen Petroleum.jpeg"
import img2 from "../../../Images/Petrolium/Petroleum water.jpg"
import img3 from "../../../Images/Petrolium/Other Petroleum extracts.jpg"
import { LazyLoadImage } from 'react-lazy-load-image-component';


const PetroleumSegment = () => {
    return (<>
        <div className="position-relative" style={{ height: "300px" }}>
            <div className="position-relative w-100 h-100" >
                 <LazyLoadImage  src={Main} loading="eager" className="img-fluid d-block w-100 h-100" style={{ filter: "brightness(50%)" }} />
                <div className="position-absolute w-100 h-100 top-0 left-0  d-flex justify-content-center align-items-center text-white">
                    <h2 className='text-center mt-3'>Petroleum Segment</h2>
                </div>
            </div>
        </div>

        <div className='container mt-5'>
            <div className='row'>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                     <LazyLoadImage   className='h-100 w-100' src={img1} alc="" />
                </div>
                <div className='col-lg-8 col-md-6 col-sm-12'>
                    <h5>Bitumen</h5>
                    <p><strong>Econx - Your Trusted Partner for Bitumen Across All Grades </strong>
                        Econx Industries is your one-stop shop for high-quality Bitumen in all grades. We cater to the diverse needs of construction, infrastructure, and industrial projects by offering:
                    </p>
                    <ul>
                        <li>Penetration Grade Bitumen: Ideal for road construction, waterproofing, and pavement sealing.</li>
                        <li>Oxidized Bitumen: Perfect for roofing applications, providing superior weather resistance.</li>
                        <li>Polymer Modified Bitumen: Offers enhanced flexibility and durability for demanding environments.</li>
                    </ul>

                    <ul>
                        <h6>Econx guarantees</h6>
                        <li>Competitive pricing and bulk supply options.</li>
                        <li>Reliable sourcing and strict quality control.</li>
                        <li>Efficient global logistics and on-time delivery.</li>
                    </ul>
                </div>
            </div>

            <div className='row mt-5 flex-md-row-reverse'>
            <div className='col-lg-4 col-md-6 col-sm-12'>
                     <LazyLoadImage   className='h-100 w-100' src={img2} alc="" />
                </div>
                <div className='col-lg-8 col-md-6 col-sm-12'>
                    <h5>Waterproofing products</h5>
                    <p><strong>Econx: Your One-Stop Shop for Reliable Waterproofing Solutions </strong>
                        Econx Industries provides a comprehensive range of high-performance waterproofing products to protect your valuable assets from water damage. We offer solutions for various applications, including:
                    </p>

                    <ul>
                        <li>Building and construction: Sealants, membranes, and coatings for roofs, foundations, and basements.</li>
                        <li>Industrial applications: Protective solutions for tanks, pipelines, and other industrial structures.</li>
                        <li>Home and garden: Waterproofing products for basements, decks, and pools.</li>
                    </ul>
                    <h6>Econx guarantees</h6>
                    <ul>
                        <li>Diverse product portfolio: Cater to a variety of needs and budgets.</li>
                        <li>Technical expertise: Assist you in selecting the optimal solution for your project.</li>
                        <li>Competitive pricing and bulk supply options: Ensure cost-effectiveness.</li>
                    </ul>
                </div>
            </div>

            <div className='row mt-5 mb-5'>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                     <LazyLoadImage   className='h-100 w-100' src={img3} alc="" />
                </div>
                <div className='col-lg-8 col-md-6 col-sm-12'>
                    <h5>Other Petroleum extracts</h5>
                    <p><strong>Econx Industries: Committed to Responsible Trading Practices </strong>
                        At Econx Industries, we are committed to responsible sourcing and ethical trade practices across all our product categories. We understand the environmental impact of certain materials and strive to offer sustainable and eco-friendly alternatives whenever possible.
                        For inquiries related to "Other Petroleum extracts," we encourage open communication and transparency. Please contact us directly to discuss your specific needs and explore potentially sustainable alternatives aligned with our commitment to environmental responsibility.
                        This content emphasizes responsible practices and encourages open communication, while avoiding promotion of potentially harmful materials.
                    </p>
                </div>
            </div>
        </div>
    </>)
}

export default PetroleumSegment