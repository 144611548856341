import * as React from 'react';
import { NavLink } from 'react-router-dom'
import ProductCss from "../Products/Product.module.css"
import img1 from "../../Images//AssamTea/ctc-Tea.png"
import img2 from "../../Images/Metal/Aluminum INGOT.jpg"
import img3 from "../../Images/Indian Spices/Carom Seeds.jpg"
import img4 from "../../Images/Petrolium/Bitumen Petroleum.jpeg"
import { AnimatedOnScroll } from "react-animated-css-onscroll";
import { LazyLoadImage } from 'react-lazy-load-image-component';


const HomeSection3 = () => {
    return (<>
        <div className={`container pt-1 mb-4 mt-4 ${ProductCss.Images}`}>
            <h4 className='text-center mt-4 mb-5'>Our Products</h4>
            <div className='row'>
                <div className='col-lg-3 col-md-3 col-sm-6'>
                <AnimatedOnScroll animationIn="fadeInLeftBig"> 
                    <div class="card" >
                         <LazyLoadImage effect="blur" class="card-img-top " src={img1} alt="Card image cap" />
                        <div class="card-body">
                            <h5 class="card-title">Assam Tea</h5>
                            <NavLink to="/AssamTea" className="nav-link ">
                                <a>Read More</a>
                            </NavLink>
                        </div>
                    </div>
                    </AnimatedOnScroll > 

                </div>
                <div className='col-lg-3 col-md-3 col-sm-6'>
                <AnimatedOnScroll animationIn="fadeInDownBig"> 
                    <div class="card" >
                         <LazyLoadImage effect="blur" class="card-img-top" src={img2} alt="Card image cap" />
                        <div class="card-body">
                            <h5 class="card-title">Metal Segment</h5>
                            <NavLink to="/MetalSegment" className="nav-link ">
                                <a>Read More</a>
                            </NavLink>
                        </div>
                    </div>
                    </AnimatedOnScroll >
                </div>
                <div className='col-lg-3 col-md-3 col-sm-6'>
                <AnimatedOnScroll animationIn="fadeInUpBig"> 
                    <div class="card" >
                         <LazyLoadImage effect="blur" class="card-img-top" src={img3} alt="Card image cap" />
                        <div class="card-body">
                            <h5 class="card-title">Indian Spices</h5>
                            <NavLink to="/IndianSpices" className="nav-link ">
                                <a>Read More</a>
                            </NavLink>
                        </div>
                    </div>
                    </AnimatedOnScroll > 
                </div>
                <div className='col-lg-3 col-md-3 col-sm-6'>
                <AnimatedOnScroll animationIn="fadeInRightBig"> 
                    <div class="card">
                         <LazyLoadImage effect="blur" class="card-img-top" src={img4} alt="Card image cap" />
                        <div class="card-body">
                            <h5 class="card-title">Petrolium</h5>
                            <NavLink to="/PetroleumSegment" className="nav-link ">
                                <a>Read More</a>
                            </NavLink>
                        </div>
                    </div>
                    </AnimatedOnScroll> 
                </div>
            </div>

        </div>
    </>)
}

export default HomeSection3


// 




