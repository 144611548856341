import React from 'react'
import ProductCss from "../Product.module.css"
import Main from "../../../Images/banner/banner/Dehydrated Products.jpg"
import img1 from "../../../Images/Dehydrated/Onion Flex.jpeg"
import img2 from "../../../Images/Dehydrated/Garlic Powder.png"
import img3 from "../../../Images/Dehydrated/Ginger Powder.jpg"
import img4 from "../../../Images/Dehydrated/Dry Tulsi.jpg"
import img5 from "../../../Images/Dehydrated/Mint.PNG"
import img6 from "../../../Images/Dehydrated/Lemon Grass.jpg"
import img7 from "../../../Images/Dehydrated/Lemon Slice Powder.jpg"
import img8 from "../../../Images/Dehydrated/Jasmine.jpg"
import img9 from "../../../Images/Dehydrated/Stevia.jpg"
import img10 from "../../../Images/Dehydrated/Maringa Leaf Powder.jpg"
import img11 from "../../../Images/Dehydrated/Rose flower.jpg"
import img12 from "../../../Images/Dehydrated/Fox Nuts.PNG"
import { LazyLoadImage } from 'react-lazy-load-image-component';




const DehydratedProducts = () => {
    return (<>
        <div className="position-relative" style={{ height: "300px" }}>
            <div className="position-relative w-100 h-100" >
                 <LazyLoadImage  src={Main} loading="eager" className="img-fluid d-block w-100 h-100" style={{ filter: "brightness(50%)" }} />
                <div className="position-absolute w-100 h-100 top-0 left-0  d-flex justify-content-center align-items-center text-white">
                    <h2 className='text-center mt-3'>Dehydrated Products</h2>
                </div>
            </div>
        </div>

        <div className={`container pt-1 ${ProductCss.Images}`}>
            <div className='row mt-5 '>
                <div className='col-lg-4 col-md-3 col-sm-6 '>
                    <div class="card" >
                         <LazyLoadImage effect="blur" class="card-img-top" src={img1} alt="Card image cap" />
                        <div class="card-body">
                            <h5>Onion Flex / Powder</h5>
                            <p class="card-text">Econx Industries supplies high-quality dehydrated onion in bulk for food manufacturers and distributors. Choose from versatile onion flex for rehydration or convenient onion powder for adding instant flavor. Econx offers competitive pricing, efficient logistics, and reliable sourcing. </p>
                        </div>
                    </div>
                </div>

                <div className='col-lg-4 col-md-3 col-sm-6 '>
                    <div class="card pb-4" >
                         <LazyLoadImage effect="blur" class="card-img-top" src={img2} alt="Card image cap" />
                        <div class="card-body">
                            <h5>Garlic Flex / Powder</h5>
                            <p class="card-text">Econx Industries offers superior garlic solutions for food businesses: versatile garlic flex for rehydration and potent garlic powder for instant flavoring. Source high-quality bulk garlic at competitive prices with reliable sourcing and efficient global logistics.</p>
                        </div>
                    </div>
                </div>

                <div className='col-lg-4 col-md-3 col-sm-6 '>
                    <div class="card" >
                         <LazyLoadImage effect="blur" class="card-img-top" src={img3} alt="Card image cap" />
                        <div class="card-body">
                            <h5>Ginger Flex / Powder</h5>
                            <p class="card-text">Econx Industries provides bulk ginger solutions for food manufacturers and distributors: adaptable ginger flex for rehydration and powerful ginger powder for instant taste. Benefit from competitive pricing, reliable sourcing, and efficient global trade.</p>
                        </div>
                    </div>
                </div>
            </div>


            <div className='row mt-2'>
                <div className='col-lg-4 col-md-4 col-sm-6 '>
                    <div class="card pb-4" >
                         <LazyLoadImage effect="blur" class="card-img-top" src={img4} alt="Card image cap" />
                        <div class="card-body">
                            <h5>Dry Tulsi</h5>
                            <p class="card-text">Source Authentic Dry Tulsi (Holy Basil) with Econx:
                                Econx Industries provides high-quality dry tulsi (holy basil) to wholesalers and distributors globally. This aromatic herb is revered for its medicinal properties and culinary versatility. Econx offers competitive pricing, ethical sourcing, and efficient global trade solutions.
                            </p>
                        </div>
                    </div>
                </div>

                <div className='col-lg-4 col-md-4 col-sm-6 '>
                    <div class="card pb-4" >
                         <LazyLoadImage effect="blur" class="card-img-top" src={img5} alt="Card image cap" />
                        <div class="card-body">
                            <h5>Mint</h5>
                            <p class="card-text">Refreshing Solutions: Bulk Mint Supply by Econx
                                Econx Industries provides high-quality, bulk mint for food, beverage, and pharmaceutical companies. Choose from dried mint leaves or powdered mint for a variety of applications. Enjoy competitive pricing, efficient logistics, and reliable sourcing.
                            </p>
                        </div>
                    </div>
                </div>

                <div className='col-lg-4 col-md-4 col-sm-6 '>
                    <div class="card" >
                         <LazyLoadImage effect="blur" class="card-img-top" src={img6} alt="Card image cap" />
                        <div class="card-body">
                            <h5>Lemon Grass</h5>
                            <p class="card-text">Elevate Aromas with Econx: Bulk Lemon Grass Supply
                                Econx Industries offers premium-quality lemon grass in bulk for food manufacturers, processors, and distributors. This aromatic herb adds a citrusy, lemongrassy flavor to curries, teas, and culinary creations. Contact us for competitive pricing, reliable sourcing, and efficient global trade solutions.
                            </p>
                        </div>
                    </div>
                </div>

                <div className='col-lg-4 col-md-3 col-sm-6 '>
                    <div class="card" >
                         <LazyLoadImage effect="blur" class="card-img-top" src={img7} alt="Card image cap" />
                        <div class="card-body">
                            <h5>Lemon Slice / Powder</h5>
                            <p class="card-text">Enhance Flavors Globally: Econx Lemon Slices & Powder
                                Econx Industries supplies bulk lemon solutions for food businesses: sun-dried lemon slices for visual appeal and potent lemon powder for concentrated flavor. Source high-quality lemons at competitive prices with reliable sourcing and efficient global logistics.
                            </p>
                        </div>
                    </div>
                </div>

                <div className='col-lg-4 col-md-3 col-sm-6 '>
                    <div class="card" >
                         <LazyLoadImage effect="blur" class="card-img-top" src={img8} alt="Card image cap" />
                        <div class="card-body">
                            <h5>Jasmine</h5>
                            <p class="card-text">Fragrant Wholesale Solution: Jasmine Flowers by Econx
                                Econx Industries supplies premium jasmine flowers in bulk for fragrances, teas, and culinary applications. Experience the intoxicating aroma and delicate taste of these ethically sourced blooming buds. Contact us for competitive pricing and reliable global trade solutions.
                            </p>
                        </div>
                    </div>
                </div>

                <div className='col-lg-4 col-md-3 col-sm-6 '>
                    <div class="card" >
                         <LazyLoadImage effect="blur" class="card-img-top" src={img9} alt="Card image cap" />
                        <div class="card-body">
                            <h5>Stevia</h5>
                            <p class="card-text">Sweeten Naturally: Bulk Stevia Supply from Econx
                                Econx Industries offers high-quality stevia leaf in bulk for food and beverage companies. This natural sweetener provides sweetness without calories, making it ideal for health-conscious consumers. Source stevia at competitive prices with reliable sourcing and efficient global trade solutions.
                            </p>
                        </div>
                    </div>
                </div>

                <div className='col-lg-4 col-md-3 col-sm-6 '>
                    <div class="card" >
                         <LazyLoadImage effect="blur" class="card-img-top" src={img10} alt="Card image cap" />
                        <div class="card-body">
                            <h5>Maringa Leaf / Powder</h5>
                            <p class="card-text">Superfood Solutions: Moringa Leaf & Powder by Econx
                                Econx Industries provides bulk Moringa leaf & powder, a nutrient-rich superfood. This versatile ingredient boasts protein, vitamins, and minerals, perfect for food, beverage, and nutraceutical companies. Source high-quality Moringa at competitive prices with reliable sourcing and efficient global trade.
                            </p>
                        </div>
                    </div>
                </div>

                <div className='col-lg-4 col-md-3 col-sm-6 '>
                    <div class="card pb-4" >
                         <LazyLoadImage effect="blur" class="card-img-top" src={img11} alt="Card image cap" />
                        <div class="card-body">
                            <h5>Rose flower</h5>
                            <p class="card-text">Add Floral Elegance: Bulk Dry Rose Flowers by Econx
                                Econx Industries supplies fragrant dry rose flowers in bulk for potpourri, teas, and decorative applications. These aromatic blooms come in various colors and offer a touch of elegance to any product. Contact us for competitive pricing, ethical sourcing, and efficient global trade solutions.
                            </p>
                        </div>
                    </div>
                </div>

                <div className='col-lg-4 col-md-3 col-sm-6 '>
                    <div class="card" >
                         <LazyLoadImage effect="blur" class="card-img-top" src={img12} alt="Card image cap" />
                        <div class="card-body">
                            <h5>Fox Nuts</h5>
                            <p class="card-text">Elevate Culinary Experiences: Bulk Makhana Supply by Econx
                                Econx Industries offers premium makhana (fox nuts) in bulk for wholesalers and distributors globally. These versatile nuts boast a crunchy texture and neutral flavor, making them ideal for sweet and savory dishes. Econx provides competitive pricing, reliable sourcing, and efficient global trade solutions.
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </>)
}

export default DehydratedProducts