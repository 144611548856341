import React from 'react'
import Main from "../../../Images/banner/banner/Textile Segment2.png"
import img1 from "../../../Images/TextileSegment/CRX1.jpg"
import img2 from "../../../Images/TextileSegment/CRT1.jpg"
import img3 from "../../../Images/TextileSegment/Jute Yarn CB.jpg"
import img4 from "../../../Images/TextileSegment/Fabrics Made Up1.jpg"
import img5 from "../../../Images/TextileSegment/Jute Sheet1.jpg"
import { LazyLoadImage } from 'react-lazy-load-image-component';


const TextileSegment = () => {
    return (<>
        <div className="position-relative" style={{ height: "300px" }}>
            <div className="position-relative w-100 h-100" >
                 <LazyLoadImage src={Main} loading="eager" className="img-fluid d-block w-100 h-100" style={{ filter: "brightness(50%)" }} />
                <div className="position-absolute w-100 h-100 top-0 left-0  d-flex justify-content-center align-items-center text-white">
                    <h2 className='text-center mt-3'>Textile Segment</h2>
                </div>
            </div>
        </div>

        <div className='container mt-5'>
            <div className='row'>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                     <LazyLoadImage effect="blur" className='h-100 w-100 img-fluid' src={img1} alc="" />
                </div>
                <div className='col-lg-8 col-md-6 col-sm-12 mt-sm-4'>
                    <h5>Jute Yarn CRX</h5>
                    <p><strong>Econx Delivers High-Quality Jute Yarn CRX in Bulk </strong>
                        Econx Industries supplies premium Jute Yarn CRX in bulk quantities to textile manufacturers and distributors worldwide. This strong, eco-friendly yarn is ideal for a variety of applications, including:
                    </p>
                    <ul>
                        <li> Rug making</li>
                        <li>Furniture upholstery</li>
                        <li>Packaging materials</li>
                        <li>Home decor accents</li>
                    </ul>
                    <p>Econx offers competitive pricing, reliable sourcing, and efficient global trade solutions.</p>
                    <strong>Contact us today to discuss your Jute Yarn CRX needs!</strong>

                </div>
            </div>

            <div className='row mt-5 flex-md-row-reverse'>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                     <LazyLoadImage effect="blur" style={{ height: "350px", width: "100%" }} className='img-fluid' src={img2} alc="" />
                </div>
                <div className='col-lg-8 col-md-6 col-sm-12 mt-sm-4'>
                    <h5>Jute Yarn CRT</h5>
                    <p><strong>Econx: Your Trusted Partner for Bulk Jute Yarn CRT </strong>
                        Econx Industries supplies high-quality Jute Yarn CRT in bulk to manufacturers and distributors globally. This versatile, eco-friendly yarn offers excellent strength and natural aesthetics, perfect for
                    </p>
                    <ul>
                        <li> Crafting ropes and twines</li>
                        <li>Weaving bags and sacks</li>
                        <li>Sustainable upholstery applications</li>
                    </ul>

                    <p> Econx provides competitive pricing, reliable sourcing, and efficient global logistics.</p>
                    <strong>Contact us today to explore the possibilities of Jute Yarn CRT!</strong>

                </div>
            </div>

            <div className='row mt-5 mb-5'>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                     <LazyLoadImage effect="blur" className='h-100 w-100 img-fluid' src={img3} alc="" />
                </div>
                <div className='col-lg-8 col-md-6 col-sm-12 mt-sm-4'>
                    <h5>Jute Yarn CB</h5>
                    <p><strong>Econx: Your One-Stop Shop for Bulk Jute Yarn CB </strong>
                        Econx Industries offers premium Jute Yarn CB in bulk quantities to textile manufacturers and distributors worldwide. This cost-effective yarn is ideal for various applications seeking affordability and natural aesthetics, including
                    </p>
                    <ul>
                        <li>Creating carpets and rugs</li>
                        <li>Weaving shopping bags and tote bags</li>
                        <li>Environmentally conscious packaging solutions</li>
                    </ul>
                    <p>Econx provides competitive pricing, reliable sourcing, and efficient global trade solutions.</p>
                    <strong>Contact us today to discuss your Jute Yarn CB requirements!</strong>

                </div>
            </div>

            <div className='row mt-5 mb-5 flex-md-row-reverse'>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                     <LazyLoadImage effect="blur" className='h-100 w-100 img-fluid' src={img4} alc="" />
                </div>
                <div className='col-lg-8 col-md-6 col-sm-12 mt-sm-4'>
                    <h5>Fabrics Made Up</h5>
                    <p><strong>Econx Industries: Sourcing Your Next Fabric Masterpiece </strong>
                        Econx Industries offers a diverse selection of high-quality fabrics in bulk to fashion designers, manufacturers, and distributors globally. We source a wide range of natural and synthetic fabrics including
                    </p>
                    <ul>
                        <li>Cotton, linen, and silk for apparel</li>
                        <li>Wool and velvet for upholstery</li>
                        <li>Canvas and denim for bags and accessories</li>
                    </ul>

                    <p>Econx provides competitive pricing, reliable sourcing, and efficient global trade solutions.</p>
                    <strong> Contact us today to explore our extensive fabric collection!</strong>

                </div>
            </div>

            <div className='row mt-5 mb-5'>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                     <LazyLoadImage effect="blur" className='h-100 w-100 img-fluid' src={img5} alc="" />
                </div>
                <div className='col-lg-8 col-md-6 col-sm-12 mt-sm-4'>
                    <h5>Jute Sheet</h5>
                    <p><strong>Econx: Your Sustainable Jute Sheet Supplier </strong>
                        Econx Industries offers premium Jute Sheets in bulk quantities for manufacturers, distributors, and retailers globally. Our eco-friendly Jute Sheets are
                    </p>
                    <ul>
                       <li> Versatile: Ideal for flooring, wall coverings, and furniture applications.</li>
                        <li>Durable: Strong and long-lasting, providing a natural aesthetic.</li>
                        <li>Sustainable: Made from a renewable resource, offering an eco-conscious choice.</li>
                    </ul>

                    <p> Econx provides competitive pricing, reliable sourcing, and efficient global trade solutions.</p>
                    <strong>Contact us today to explore the possibilities of Jute Sheet for your next project!</strong>

                </div>
            </div>

        </div>


    </>)
}

export default TextileSegment