import React from 'react'
import Main from "../../../Images/banner/banner/Metal Segment.jpg"
import img1 from "../../../Images/Metal/Copper Cathode1.jpg"
import img2 from "../../../Images/Metal/Milberry Scrap.jpg"
import img3 from "../../../Images/Metal/IRON Scrap.jpg"
import img4 from "../../../Images/Metal/Aluminum INGOT.jpg"
import img5 from "../../../Images/Metal/Aluminum Scrap.jpg"
import { LazyLoadImage } from 'react-lazy-load-image-component';


const MetalSegment = () => {
  return (<>
    <div className="position-relative" style={{ height: "300px" }}>
      <div className="position-relative w-100 h-100" >
         <LazyLoadImage  src={Main} loading="eager" className="img-fluid d-block w-100 h-100" style={{ filter: "brightness(70%)" }} />
        <div className="position-absolute w-100 h-100 top-0 left-0  d-flex justify-content-center align-items-center text-white">
          <h2 className='text-center mt-3'>Metal Segment</h2>
        </div>
      </div>
    </div>

    <div className='container mt-5'>
      <div className='row'>
        <div className='col-lg-4 col-md-6 col-sm-12'>
           <LazyLoadImage  className='h-100 w-100 img-fluid' src={img1} alc="" />
        </div>
        <div className='col-lg-8 col-md-6 col-sm-12 mt-sm-4'>
          <h5>Copper Cathode</h5>
          <p><strong>Econx - Your Reliable Partner for High-Quality Copper Cathode, Rod, Pipe </strong>
            Econx Industries is your trusted supplier of premium Copper Cathode for various industries. Our electrolytic tough pitch (ETP) grade copper caters to diverse applications, including:
          </p>
          <ul>
            <li>Electrical wire and cable production</li>
            <li>Electronics manufacturing</li>
            <li>Renewable energy solutions</li>
            <li>Construction and plumbing applications</li>
          </ul>

          <ul>
            <h6>Econx offers</h6>
            <li>Competitive pricing and bulk supply options.</li>
            <li>Strict quality control measures ensuring consistent purity.</li>
            <li>Reliable sourcing and efficient global logistics.</li>
            <li>Commitment to responsible sourcing practices.</li>
          </ul>
        </div>
      </div>

      <div className='row mt-5 flex-md-row-reverse'>
        <div className='col-lg-4 col-md-6 col-sm-12'>
           <LazyLoadImage  style={{height:"350px",width:"100%"}} className='img-fluid' src={img2} alc="" />
        </div>
        <div className='col-lg-8 col-md-6 col-sm-12 mt-sm-4'>
          <h5>Milberry Scrap</h5>
          <p><strong>Econx Industries: Sustainable Solutions for Millberry Scrap Management </strong>
            Econx Industries offers responsible and sustainable solutions for Millberry scrap management. We understand the challenges associated with metal waste and are committed to:
          </p>

          <ul>
            <li> Diverting scrap from landfills through responsible recycling practices.</li>
            <li>Partnering with mills and manufacturers to establish efficient scrap collection systems.</li>
            <li>Processing scrap using eco-friendly methods to minimize environmental impact.</li>
          </ul>

          <ul>
            <h6>By choosing Econx, you gain:</h6>
            <li>A reliable partner for responsible scrap management.</li>
            <li>Competitive pricing for your millberry scrap.</li>
            <li>Peace of mind knowing you're contributing to a sustainable future.</li>
          </ul>
        </div>
      </div>

      <div className='row mt-5 mb-5'>
        <div className='col-lg-4 col-md-6 col-sm-12'>
           <LazyLoadImage className='h-100 w-100 img-fluid' src={img3} alc="" />
        </div>
        <div className='col-lg-8 col-md-6 col-sm-12 mt-sm-4'>
          <h5>IRON Scrap</h5>
          <p><strong>Econx Industries: Your Trusted Partner for IRON Scrap Recycling [HMS 1, 2, 3, Cast IRON] </strong>
            Econx Industries is a leading global trader of high-quality IRON Scrap. We offer a reliable and sustainable solution for:
          </p>
          <ul>
            <li>	Construction and demolition companies: Remove and responsibly recycle iron scrap.</li>
            <li>Metal fabrication shops: Clear excess iron materials efficiently.</li>
            <li>Manufacturing facilities: Dispose of iron waste in an environmentally friendly way.</li>
          </ul>
          <ul>
            <h6>Econx provides</h6>
            <li>Competitive pricing for all grades of iron scrap.</li>
            <li>Convenient collection and transportation options.</li>
            <li>Strict adherence to responsible recycling practices.</li>
            <li>Transparency and traceability throughout the process.</li>
          </ul>
        </div>
      </div>

      <div className='row mt-5 mb-5 flex-md-row-reverse'>
        <div className='col-lg-4 col-md-6 col-sm-12'>
           <LazyLoadImage  className='h-100 w-100 img-fluid' src={img4} alc="" />
        </div>
        <div className='col-lg-8 col-md-6 col-sm-12 mt-sm-4'>
          <h5>Aluminum INGOT</h5>
          <p><strong>Econx Industries: Your One-Stop Shop for High-Quality Aluminum Ingot </strong>
            Econx Industries is your trusted supplier of premium Aluminum Ingot for various industries. Our high-purity ingots cater to diverse applications, including:
          </p>
          <ul>
            <li>Automotive and aerospace manufacturing</li>
            <li>Construction and building materials</li>
            <li>Food and beverage packaging</li>
            <li>Consumer electronics and appliances</li>
          </ul>
          <ul>
            <h6>Econx offers</h6>
            <li>Competitive pricing and flexible order quantities.</li>
            <li>Multiple ingot sizes and alloys to meet specific needs.</li>
            <li>Strict quality control measures ensuring consistent performance.</li>
            <li>Reliable sourcing and efficient global logistics.</li>
          </ul>
        </div>
      </div>

      <div className='row mt-5 mb-5'>
        <div className='col-lg-4 col-md-6 col-sm-12'>
           <LazyLoadImage  className='h-100 w-100 img-fluid' src={img5} alc="" />
        </div>
        <div className='col-lg-8 col-md-6 col-sm-12 mt-sm-4'>
          <h5>Aluminum Scrap</h5>
          <p><strong>Econx Industries: Transforming Aluminum Scrap into Sustainable Solutions </strong>
            Econx Industries offers a responsible and cost-effective solution for your Aluminum Scrap. We:
          </p>
          <ul>
            <li>Divert scrap from landfills through eco-friendly recycling practices.</li>
            <li>Partner with businesses to establish efficient collection and processing systems.</li>
            <li>Offer competitive pricing for various grades of aluminum scrap.</li>
          </ul>

          <ul>
            <h6>Benefits of partnering with Econx</h6>
            <li> Reduce your environmental footprint.</li>
            <li>Gain a reliable and responsible scrap management partner.</li>
            <li>Contribute to a circular economy for valuable resources.</li>
          </ul>

        </div>
      </div>
    </div>
  </>)
}

export default MetalSegment