import React from 'react'
import { FaCheckCircle } from "react-icons/fa";

const AboutSection4 = () => {
    return (<>
        <div className='container pt-1'>
            <div className='mt-4'>
                <h4>Our Strengths</h4>
                <p><FaCheckCircle /><strong> Customer-centric approach:</strong>We prioritize understanding our partners' needs and exceeding their expectations.</p>
                <p><FaCheckCircle /><strong> Resource optimization:</strong>We strive to utilize resources effectively to deliver the best possible outcomes. </p>
                <p><FaCheckCircle /><strong> Long-term partnerships:</strong>We value building lasting and trust-based relationships with our clients.</p>
                <p><FaCheckCircle /><strong> Scalability:</strong>We are equipped to adapt and grow alongside our partners' evolving needs.</p>
                <p><FaCheckCircle /><strong> Dedicated team:</strong>  We possess a committed workforce passionate about delivering customer success.</p>
            </div>
        </div>

        <div className='container pt-1'>
            <div className='mt-4'>
                <h4>Quality Policy</h4>
                <strong>Econx Industries Limited is committed to</strong>
                <p><FaCheckCircle /><strong> Exceeding customer expectations :</strong>through timely product delivery, exceptional service, and strict adherence to all statutory and regulatory requirements.</p>
                <p><FaCheckCircle /><strong> Continuously improving the quality:</strong>of our services and the effectiveness of our quality management system.</p>
                <p><FaCheckCircle /><strong> Building long-term satisfaction:</strong>and trust with our business partners.</p>
            </div>
        </div>

    </>)
}

export default AboutSection4