import React from 'react'
import Nav from "./Compnents/Navbar/nav"
import Home from "./Compnents/Home/Home"
import Commodities from './Compnents/Products/Commodities/Commodities'
import Footer from './Compnents/Footer/Footer'

import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from './Compnents/About/About'
import Contact from './Compnents/Contact/Contact'
import FreshFruitsVegetables from './Compnents/Products/Fresh Fruits & Vegetables/FreshFruits&Vegetables'
import AssamTea from './Compnents/Products/Assam Tea/AssamTea'
import EdibleOil from './Compnents/Products/Edible Oil/EdibleOil'
import MetalSegment from './Compnents/Products/Metal Segment/MetalSegment'
import IndianSpices from './Compnents/Products/Indian Spices/IndianSpices'
import DehydratedProducts from './Compnents/Products/Dehydrated Products/DehydratedProducts'
import TextileSegment from './Compnents/Products/Textile Segment/TextileSegment'
import PetroleumSegment from './Compnents/Products/Petrolium Segment/PetroleumSegment'
import Services from "./Compnents/Service/Service"
import { Helmet } from 'react-helmet'
import AngleUp from "./Compnents/AngleUp/AngleUp";
import ScrollToTop from './Compnents/Scroll Top/ScrollToTop'

import { FloatingWhatsApp } from 'react-floating-whatsapp'

export const App = () => {

  return (<>
    <BrowserRouter>
      <Helmet>
        <title>Econx</title>
        <meta name="keywords" content="Commodities supplier,
    Metals importer & Trader, 
    Premium Export,
    Premium Import,
    Global Business services from India,
    International Trader,
    Premium Supply of food Chain,
    Logistics and Packaging services provider,
    Premium Commodity trader,
    Metal Trading Partner,
    Risk Management,
    Import-Export Services,
    Shipping Solution Provider,
    Customs Clearance Assistance,
    Global Sourcing and Procurement,
    Procurement Services Provider,
    Supplier Management,
    Export Documentation,
    Import Regulations,
    Export Compliance Services,
    1121 Basmati Rice,
    IR 64 Rice,
    Premium Indian Spices Supplier,
    Premium Indian Spice Powder Supplier,
    Premium Spice Manufacturer,
    Copper Millberry importer,
    Copper Cathode importer,
    Copper Rod supplier,
    Aluminum Scrap importer,
    Aluminum Ingot Supplier,
    IRON Scrap Importer,
    Indian Exporter,
    Indian Importer,
    Global Procurement Services,
    Export Packaging Services,
    Bitumen,
    Waterproofing importer,
    Basmati Rice,
    Golden Sella,
    White Sella,
    Fox Nuts,
    Exporter from India,
    Indian Textile Supplier,
    Indian Bitumen Supplier,
    Dehydrated Food Product Supplier from India,
    Manufacturer of Indian Spices,
    FMCG Bulk manufacturer,
    Export Solution Provider,
    Banana Exporter from India,
    Pomegranate Exporter from India,
    Premium Vegetable Supplier Abu Dhabi,
    Premium Vegetable Supplier Dubai,
    Premium Fruits Supplier Abu Dhabi,
    Premium Banana Supplier Turkey,
    Premium Pomegranate Supplier Turkey,
    Exporter to St. Petersburg Fruit Exporter,
    Moscow Vegetable Supplier,
    Organic Tea Exporter to European Countries,
    Organic Spice Exporter to European,
    Onion Exporter from India,
    Onion Exporter from to Malaysia, 
    Sea Salt exporter from India,
    Sustainable Agriculture Exporter to Melbourne,
    Sustainable Agriculture Exporter to Germany,
    Sustainable Agriculture Exporter to Moscow, 
      Premium Coffee Supplier to New York,
      Premium exporter of Fruits to Dubai, 
      Star exporters from India,
      Premium Vegetables Exporter to Russia,
      Premium Exporter to Moscow,
      Premium Exporter to Russian Federation,
      Premium Exporter to European Countries,
      Premium Exporter to Eurasian Economic Union,
      Premium Exporter to Germany,
      Premium Exporter to Frankfurt,
      Premium Supplier to European Union,
      Premium exporter to Southeast Asia Countries,
      Premium  exporter to ASEAN Countries,
      Premium  exporter to Singapore,
      Premium  exporter to Australia,
      Premium  exporter to Sydney,
      Premium exporter to USA,
      Premium exporter to Middle East,
      Premium  exporter to Gulf Cooperation Council,
      Premium  exporter to New Work,
      Premium exporter to North America,
      Premium exporter to South America,
      largest Rice Supplier to East African,
      largest Rice Supplier to West African, 
      largest Rice Supplier from India"/>
        <meta name="description" content="Global Trading Partner" />
        <meta name="keyphrases" content="Premium  exporter to New Work,Premium  exporter to Singapore,Sustainable Agriculture Exporter to Germany,Copper Rod supplier, Global Business services from India,Indian Textile Supplier,Organic Tea Exporter to European Countries" />
        <meta name="author" content=" Designed and Promoted by GenXcel Info Solutions Pvt Ltd, https://econxi.com/" />
        <meta name="copyright" content="https://econxi.com/" />
        <meta name="rating" content="general" />
        <meta name="robots" content="index, follow" />
        <meta name="identifier-url" content="https://econxi.com/" />
        <meta name="owner" content="pune@econxi.com" />
        <meta name="revisit-after" content="4 days" />
        <meta name="language" content="english" />
        <meta name="subject" content="Global Trading Partner" />
        <meta name="audience" content="All" />
        <meta name="distribution" content="global" />
        <meta name="coverage" content="worldwide" />
      </Helmet>
      <Nav />
      <FloatingWhatsApp phoneNumber="+918983000373" accountName="Econxi" allowClickAway="true"/>
      <AngleUp />
      <ScrollToTop smooth/>
      <Routes>
       
        <Route path='/' element={<Home />} />
        <Route path='/About' element={<About />} />
        <Route path='/Commodities' element={<Commodities />} />
        <Route path='/Contact' element={<Contact />} />
        <Route path='/FreshFruits&Vegetables' element={<FreshFruitsVegetables />} />
        <Route path='/AssamTea' element={<AssamTea />} />
        <Route path='/EdibleOil' element={<EdibleOil />} />
        <Route path='/MetalSegment' element={<MetalSegment />} />
        <Route path='/IndianSpices' element={<IndianSpices />} />
        <Route path='/DehydratedProducts' element={<DehydratedProducts />} />
        <Route path='/PetroleumSegment' element={<PetroleumSegment />} />

        <Route path='/Services' element={<Services />} />

        <Route path='/TextileSegment' element={<TextileSegment />} />
      

      </Routes>
      <Footer />
    </BrowserRouter>

  </>)
}
